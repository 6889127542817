import Aside from "../components/layout/Aside";
import Header from "../components/layout/Header";
import { UIProvider } from "../context/ui";

const WithLayout = ({ children }) => {
	return (
		<UIProvider>
			<main>
				<Aside />
				<section className='container'>
					<Header />
					{children}
				</section>
			</main>
		</UIProvider>
	);
};

export default WithLayout;
