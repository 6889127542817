import { useEffect } from "react";
import { API_URL } from "../../utilities/constants";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { useFetch } from "../../hooks/useFetch";
import { CiMail, CiMoneyCheck1, CiUser } from "react-icons/ci";
import { decodeRolesFromToken } from "../../utilities/helpers";

const Profile = () => {
	const { setSegmentTitle } = useUI();

	const { data, error, loading } = useFetch(
		API_URL + "/api/1.0.0/user/profile",
		{
			method: "GET",
		}
	);

	useEffect(() => {
		setSegmentTitle("Profile");
	}, []);


	const getRespectiveUI = () => {
		const me = decodeRolesFromToken();
		const myRoleId = me[0];
		switch (myRoleId) {
			case 1:
				return "Gasolina Super Admin";
			case 2:
				return data?.data?.fullName + " - Gasolina Sub Admin";
			case 3:
				return data?.data?.fullName + " - Financier";
			case 4:
				return data?.data?.fullName + " - Petrol Attendant";
			case 5:
				return data?.data?.fullName + " - Customer";
			case 6:
				return data?.data?.fullName + " - Cient Admin";
			case 7:
				return data?.data?.fullName + " - Cashier";
			case 8:
				return data?.data?.fullName + " - Client Sub Admin";
			default:
				return "Gasolina User";
		}
	};

	const showWalletUI = () => {
		const me = decodeRolesFromToken();
		return me.includes(1);
	};

	return (
		<section>
			<PageTitle title='Profile' />
			<FullPageLoader isActive={loading} />
			{data && (
				<section id='profile-page'>
					<div
						className='cover-image b-r-10 default-image'
						style={{
							backgroundImage: data.data.coverPictureURL
								? `url(${data.data.coverPictureURL})`
								: null,
						}}></div>
					<div className='user-info'>
						<div
							className='default-image user-picture b-r-100 pos-rel'
							style={{
								backgroundImage: data.data.profilePictureURL
									? `url(${data.data.profilePictureURL})`
									: null,
							}}></div>
						<div className='t-a-l'>
							<div
								className='flex center-center'
								style={{ gap: "8px", paddingTop: "60px" }}>
								<h5 className='color-primary l-h-1'>
									{data.data.fullName}
								</h5>
								<p
									className='color-light f-w-300'
									style={{ fontSize: "11px" }}>
									({getRespectiveUI()})
								</p>
							</div>
						</div>
					</div>
					<div className='rs-tabs'>
						<ul className='tab-selector'>
							<li className='active'>About</li>
						</ul>
						<div className='tab-content'>
							<div className='about-content'>
								<div className='left'>
									<p className='color-primary f-w-700'>
										About
									</p>
									<br />
									<ul>
										<li className='font-size-14 b-r-5 active'>
											Overview
										</li>
									</ul>
								</div>
								<div className='right'>
									<p className='f-w-700 color-primary'>
										Overview
									</p>
									<br />
									<div
										className='flex center-top'
										style={{ gap: "12px" }}>
										<CiUser />
										<div>
											<p
												className='color-light l-h-1'
												style={{
													fontSize: "11px",
													marginBottom: "5px",
												}}>
												Fullname
											</p>
											<p className='color-primary l-h-1'>
												{data.data.fullName}
											</p>
										</div>
									</div>
									<br />
									<div
										className='flex center-top'
										style={{ gap: "12px" }}>
										<CiMail />
										<div>
											<p
												className='color-light l-h-1'
												style={{
													fontSize: "11px",
													marginBottom: "5px",
												}}>
												Email
											</p>
											<p className='color-primary l-h-1'>
												{data.data.email}
											</p>
										</div>
									</div>
									<br />
									{showWalletUI() && (
										<div
											className='flex center-top'
											style={{ gap: "12px" }}>
											<CiMoneyCheck1 />
											<div>
												<p
													className='color-light l-h-1'
													style={{
														fontSize: "11px",
														marginBottom: "5px",
													}}>
													Account No.
												</p>
												<p className='color-primary l-h-1'>
													{
														data.data
															.walletAccountDetails
															.accountNumber
													}
												</p>
											</div>
										</div>
									)}
									<br />
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</section>
	);
};
export default Profile;
