import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const useOutsideClick = ({ state, callBack }) => {
	const node = useRef();
	useEffect(() => {
		if (state) {
			document.addEventListener("mousedown", handleOutsideClick);
		} else {
			document.removeEventListener("mousedown", handleOutsideClick);
		}
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [state]);

	const handleOutsideClick = (e) => {
		if (node.current && node.current.contains(e.target)) {
			return;
		}
		callBack(false);
	};

	return [node];
};


export const ProtectedRoute = ({ children }) => {
	const token = localStorage.getItem("token");
  
	if (!token) {
	  return <Navigate to="/" />;
	}
  
	try {
	  const { exp } = jwtDecode(token);
	 	if (Date.now() / 1000 > exp) {
		localStorage.removeItem("token");
		return <Navigate to="/" />;
	  }
	  
	  return children;
	} catch (err) {
	  localStorage.removeItem("token");
	  return <Navigate to="/" />;
	}
  };
  
   
