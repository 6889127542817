import { CiCirclePlus } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { useEffect, useState } from "react";
import { API_URL, APP_ROLES } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";

const Users = () => {
	const [data, setData] = useState(null);
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingClient, setLoadingClient] = useState(false);
	const [loadingGasstation, setLoadingGasstation] = useState(false);
	const { setSegmentTitle } = useUI();
	const [selectedClientId, setSelectedClientId] = useState("");
	const [clientData, setClientData] = useState(null);
	const [selectedGasStationId, setSelectedGasStationId] = useState("");
	const [selectedRoleId, setSelectedRoleId] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
	const pageSize = new URLSearchParams(location.search).get("pageSize") || 10;


	// Fetch the list of clients
	const callClientListApi = async () => {
		setLoadingClient(true);
		try {
			const resp = await fetch(`${API_URL}/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setClientData(response.data);
			}
		} catch (e) {
			console.error("Error", e);
		} finally {
			setLoadingClient(false);
		}
	};

	// Fetch the list of sites, optionally filtered by client ID
	const callListApi = async () => {
		setLoadingGasstation(true);
		let endpoint = `/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc`;

		if (selectedClientId) {
			endpoint += `&clientID=${selectedClientId}`;
		}

		try {
			const resp = await fetch(API_URL + endpoint, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});

			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			} else {
				setData(null);
			}
		} catch (e) {
			console.error("Error", e);
		} finally {
			setLoadingGasstation(false);
		}
	};

	// Fetch the list of users
	const callUserApi = async () => {
		setLoading(true);

		let endpoint = `/api/1.0.0/user?pageNo=${pageNo}&pageSize=${pageSize}`;

		if (selectedGasStationId) {
			endpoint += `&gasStationID=${selectedGasStationId}`;
		}
		if (selectedRoleId) {
			endpoint += `&roleID=${selectedRoleId}`;
		}
		if (selectedClientId) {
			endpoint += `&clientID=${selectedClientId}`;
		}

		try {
			const resp = await fetch(API_URL + endpoint, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (resp.ok) {
				const response = await resp.json();
				setUsers(response.data);
			} else {
				setUsers(null);
			}
		} catch (e) {
			console.error("Error", e);
			setUsers(null);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callClientListApi();
		callListApi();
		callUserApi();
		setSegmentTitle("Users");
	}, [location.search, selectedClientId, selectedGasStationId, selectedRoleId]);

	const handleClientChange = async (event) => {
		const clientID = event.target.value;
		setSelectedClientId(clientID);
		setSelectedGasStationId(""); // Reset  selection
		setSelectedRoleId(""); // Reset role selection
		navigate(`/users?pageNo=${pageNo}&pageSize=${pageSize}&clientID=${clientID}`);
	};
	
	const handleGasStationChange = (event) => {
		const gasStationId = event.target.value;
		setSelectedGasStationId(gasStationId);
		setSelectedRoleId("");
		const currentClientId = selectedClientId ? `&clientID=${selectedClientId}` : "";
		const currentRoleId = selectedRoleId ? `&roleID=${selectedRoleId}` : "";
		navigate(`/users?pageNo=0&pageSize=${pageSize}&gasStationID=${gasStationId}${currentClientId}${currentRoleId}`);
	};
	
	const handleRoleChange = (event) => {
		const roleId = event.target.value;
		setSelectedRoleId(roleId);
		const currentClientId = selectedClientId ? `&clientID=${selectedClientId}` : "";
		const currentGasStationId = selectedGasStationId ? `&gasStationID=${selectedGasStationId}` : "";
		navigate(`/users?pageNo=0&pageSize=${pageSize}&roleID=${roleId}${currentClientId}${currentGasStationId}`);
	};

	const userRole = decodeRolesFromToken()[0];

	const getRoleDetails = (roleId) => {
		const status = APP_ROLES.find((obj) => obj.roleId === roleId);
		return status
			? status
			: {
				roleId: 0,
				title: "Unknown", 
				titleBeautify: "Unknown",
			  };
	};

	return (
		<>
			<PageTitle title='Users' />
			<FullPageLoader isActive={loading || loadingClient || loadingGasstation} />
			<section>
				<div className='flex center-left responsive-container'>
					<ResultsPerPage targetURL={`/users?pageNo=0&pageSize=`} />
					{(userRole === 1 || userRole === 2) && (
						<select
							onChange={handleClientChange}
							className="responsive-select"
							value={selectedClientId}
						>
							<option value="">All Client</option>
							{clientData &&
								clientData.clients &&
								clientData.clients.map((c, i) => (
									<option value={c.id} key={i}>
										{c.name}
									</option>
								))}
						</select>
					)}

					<select onChange={handleGasStationChange} className="responsive-select" value={selectedGasStationId}>
						<option value=''>All Site</option>
						{data && data.gasStations && data.gasStations.map((c, i) => (
							<option value={c.id} key={i}>
								{c.name}
							</option>
						))}
					</select>

					<select onChange={handleRoleChange} className="responsive-select" value={selectedRoleId}>
						<option value=''>Select User Type</option>
						{(userRole === 1 || userRole === 2) && (
							<>
								{userRole === 1 && !selectedClientId && !selectedGasStationId && <option value='2'>Gasolina Sub Admin</option>}
								{userRole === 1 && !selectedGasStationId && <option value='6'>Client Admin</option>}
								{userRole === 2 && !selectedGasStationId && <option value='6'>Client Admin</option>}
								{!selectedGasStationId && <option value='3'>Financier</option>}
								<option value='4'>Petrol Attendant</option>
								<option value='7'>Cashier</option>
								{(userRole === 2  || userRole === 1)&& !selectedGasStationId && <option value='9'>Gasolina Finance Manager</option>}
								<option value='10'>Site Manager</option>
								{!selectedGasStationId && <option value='8'>Client Sub Admin</option>}
							</>
						)}
						{(userRole === 6 || userRole === 8) && (
							<>
								{!selectedGasStationId && <option value='3'>Financier</option>}
								<option value='4'>Petrol Attendant</option>
								<option value='10'>Site Manager</option>
								<option value='7'>Cashier</option>
								{userRole === 6 && <option value='8'>Client Sub Admin</option>}
							</>
						)}
					</select>

					{userRole === 1 && (
						<div className="responsive-button">
							<NavLink to="/users/add-subadmin">
								<ButtonTextIcon
									title="Add Users"
									icon={<CiCirclePlus />}
								/>
							</NavLink>
						</div>
					)}

					{(userRole === 6 || userRole === 8) && (
						<div className="responsive-button">
							<NavLink to="/users/add">
								<ButtonTextIcon
									title="Add User"
									icon={<CiCirclePlus />}
								/>
							</NavLink>
						</div>
					)}
				</div>
				<div className='results'>
					<div className='rs-table-holder'>
						<table className="rs-table">
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Email</th>
									<th>Client Name</th>
									<th>Site Name</th>
									<th>User Type</th>
								</tr>
							</thead>
								<tbody>
									{users && users.profileResponseDTOS?.map((d, i) => (
										<tr key={i}>
											<td>{users.pageNo * users.pageSize + i + 1}</td>
											<td>{d.fullName || "-"}</td>
											<td>{d.email || "-"}</td>
											<td>{d.clientName || "-"}</td>
											<td>{d.gasStationName || "-"}</td>
											<td>
												{getRoleDetails(d.roleID).titleBeautify}
												{/* {d.roleID === 3 ? 'Finance' :
													d.roleID === 2 ? 'Gasolina Sub Admin' :
														d.roleID === 4 ? 'Petrol Attendant' :
															d.roleID === 6 ? 'Client Admin' :
																d.roleID === 7 ? 'Cashier' :
																	d.roleID === 8 ? 'Client Sub Admin' :
																		'Unknown'} */}
											</td>
										</tr>
									))}
									{(!users || !users.profileResponseDTOS || users.profileResponseDTOS.length === 0) && !loading && (
										<tr>
											<td colSpan={6} style={{ textAlign: "center", padding: "10px" }} >
												<p> No records found. </p>
											</td>
										</tr>
									)}
								</tbody>
						</table>
					</div>
				</div>
				<br />
				{users && users.totalRecords !== null && (
					<Pagination
						totalRecords={users.totalRecords}
						pageNo={users.pageNo}
						pageSize={users.pageSize}
						baseUrl='/users?'
					/>
				)}

			</section>
		</>
	);
};

export default Users;


