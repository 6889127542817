import { useCallback, useEffect, useState } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiSearch, CiMoneyBill, CiFileOn, CiRedo, CiFilter, CiInboxIn, CiSaveDown1 } from "react-icons/ci";
import { API_URL, APP_CURRENCY, DEFAULT_PER_PAGE } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import RSTabs from "../../components/ui/RSTabs";
import { formatAmountValue, formatDateTime } from "../../utilities/helpers";

const buildQueryParams = (params) => Object.entries(params)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const today = new Date();

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

const handleDateRangeChange = (dateRangeType, setFieldValue) => {
    setFieldValue("dateRangeType", dateRangeType);
    switch (dateRangeType) {
        case "YESTERDAY":
            const yesterday = new Date(today);
            const yesterday2 = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            yesterday2.setDate(today.getDate() - 2);
            setFieldValue("startDate", formatDate(yesterday2));
            setFieldValue("endDate", formatDate(yesterday));
            break;
        case "LAST_7_DAYS":
            const last7Days = new Date(today);
            last7Days.setDate(today.getDate() - 7);
            setFieldValue("startDate", formatDate(last7Days));
            setFieldValue("endDate", formatDate(today));
            break;
        case "LAST_30_DAYS":
            const last30Days = new Date(today);
            last30Days.setDate(today.getDate() - 30);
            setFieldValue("startDate", formatDate(last30Days));
            setFieldValue("endDate", formatDate(today));
            break;
        case "CUSTOM_RANGE":
            setFieldValue("startDate", "");
            setFieldValue("endDate", "");
            break;
        default:
            break;
    }
};

const ValidationSchema = Yup.object().shape({
    reportType: Yup.string().required("Report type is required"),
    dateRangeType: Yup.string().required("Date range type is required"),
    startDate: Yup.string().required("From Date is required"),
    endDate: Yup.string().required("To date is required"),
});

const getEndPoint = (reportType) => {
    switch (reportType) {
        case "TOP_UP_REPORT":
            return "/api/1.0.0/settlement/reconciliation/top-up";
        case "CUSTOMER_SITE_REPORT":
            return "/api/1.0.0/settlement/reconciliation/cust-debit";
        case "SITE_DEBIT_REPORT":
            return "/api/1.0.0/settlement/reconciliation/gs-debit";
        default:
            return null;
    }
};

const fetchReportData = async (url) => {
    try {
        const response = await fetch(url, {
            headers: { "X-JWT-Assertion": localStorage.getItem("token") }
        });

        if (response.ok) {
            const data = await response.json();
            if (data.result.responseCode === 200) {
                return data;
            }
        }
    } catch (error) {
        return null;
        console.error("Error fetching data from", url, error);
    }
    return null;
};

const ReconciliationReport = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("firstTab");
    const [reportData1, setReportData1] = useState(null);
    const [reportData2, setReportData2] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { setSegmentTitle } = useUI();
    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get("pageNo") || 0;
    const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
    const endDate = searchParams.get("endDate") || "";
    const startDate = searchParams.get("startDate") || "";
    const reportType = searchParams.get("reportType") || "";
    const dateRangeType = searchParams.get("dateRangeType") || "";

    const handleSubmit = async (values) => {
        const queryParams = buildQueryParams({
            reportType: values.reportType,
            endDate: values.endDate,
            startDate: values.startDate,
            dateRangeType: values.dateRangeType,
            pageNo: 0,
            pageSize: DEFAULT_PER_PAGE
        });
        navigate(`/reconciliation-report?${queryParams}`);
    };

    const callApi = async () => {
        setLoading(true);
        setReportData1(null);
        setReportData2(null);
        try {
            const queryParams = buildQueryParams({
                sortOrder: "desc",
                reportType,
                endDate,
                startDate,
                pageNo,
                pageSize
            });

            const endPoint = getEndPoint(reportType);
            if (!endPoint) return;

            const api1Response = await fetchReportData(`${API_URL}${endPoint}?${queryParams}`);
            if (api1Response) {
                setReportData1(api1Response.data);
                if (reportType === "CUSTOMER_SITE_REPORT") {
                    const api2Response = await fetchReportData(`${API_URL}/api/1.0.0/settlement/reconciliation/gs-credit?${queryParams}`);
                    if (api2Response) {
                        setReportData2(api2Response.data);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching report data", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        callApi();
        setSegmentTitle("Reconciliation Report");
    }, [location.search]);

    const tabOptions = [
        {
            id: "firstTab",
            title: reportType === "TOP_UP_REPORT" ? "Top Up Report" : reportType === "SITE_DEBIT_REPORT" ? "Site Debit Report" : "Customers Debit Report",
            content: (<TableView data={reportData1} loading={loading} />)
        },

        ...(reportType === "CUSTOMER_SITE_REPORT"
            ? [
                {
                    id: "secondTab",
                    title: "Site Credit Report",
                    content: (<TableView data={reportData2} loading={loading} />),
                },
            ]
            : []),
    ];

    return (
        <section className="pos-rel">
            <PageTitle title="Reconciliation Report" />
            <FullPageLoader isActive={loading} />
            <Formik
                initialValues={{
                    reportType: reportType,
                    dateRangeType: dateRangeType,
                    startDate: startDate,
                    endDate: endDate
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, { validateForm }) => {
                    handleSubmit(values);
                }}>
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    resetForm,
                }) => {
                    return (
                        <Form>
                            <div className="flex center-left responsive-container">
                                <div>
                                    <p className="title f-w-700 color-primary" style={{ marginBottom: "5px", fontSize: "14px" }}>Report Type :</p>
                                    <Field
                                        as='select'
                                        name='reportType'
                                        className={"responsive-select" + (errors.reportType && touched.reportType ? " error" : "")}
                                    >
                                        <option value="">Report Type</option>
                                        <option value="TOP_UP_REPORT">Top Up Report</option>
                                        <option value="CUSTOMER_SITE_REPORT">Customers Site Report</option>
                                        <option value="SITE_DEBIT_REPORT">Site Debit Report</option>
                                    </Field>
                                </div>
                                <div>
                                    <p className="title f-w-700 color-primary" style={{ marginBottom: "5px", fontSize: "14px" }}>Filter :</p>
                                    <Field
                                        as='select'
                                        name='dateRangeType'
                                        className={"responsive-select" + (errors.dateRangeType && touched.dateRangeType ? " error" : "")}
                                        onChange={(event) => { handleDateRangeChange(event.target.value, setFieldValue) }}
                                    >
                                        <option value="CUSTOM_RANGE">Custom Range</option>
                                        <option value="YESTERDAY">Yesterday</option>
                                        <option value="LAST_7_DAYS">Last 7 Days</option>
                                        <option value="LAST_30_DAYS">Last 30 Days</option>
                                    </Field>
                                </div>
                                <div>
                                    <p className="title f-w-700 color-primary" style={{ marginBottom: "5px", fontSize: "14px" }}>From Date :</p>
                                    <Field type="date" name="startDate"
                                        disabled={values.dateRangeType !== "CUSTOM_RANGE"}
                                        className={"responsive-select" + (errors.startDate && touched.startDate ? " error" : "")}
                                    />
                                </div>
                                <div>
                                    <p className="title f-w-700 color-primary" style={{ marginBottom: "5px", fontSize: "14px" }}>To Date :</p>
                                    <Field type="date" name="endDate"
                                        disabled={values.dateRangeType !== "CUSTOM_RANGE"}
                                        className={"responsive-select" + (errors.endDate && touched.endDate ? " error" : "")}
                                    />
                                </div>
                                <div>
                                    <p>&nbsp;</p>
                                    <ButtonTextIcon type="submit" title={"Search"} icon={<CiSearch />} />
                                </div>
                                <div>
                                    <p>&nbsp;</p>
                                    <ButtonTextIcon
                                        title="Reset"
                                        type="button"
                                        onClick={() => { resetForm(); setReportData1(null); setReportData2(null); navigate(`/reconciliation-report`); }}
                                        icon={<CiRedo />}
                                    />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <div className='pad-20'>
                {reportData1 && <RSTabs
                    options={tabOptions}
                    activeTab={activeTab}
                    onChange={setActiveTab}
                />}
                {!reportData1 && <>
                    <div className="results">
                        <div className="rs-table-holder">
                            <table className="rs-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Account no.</th>
                                        <th>Associated acc.</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={8} style={{ textAlign: "center", padding: "10px" }} >
                                            <p> No records found. </p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </>}
            </div>
        </section>
    );


}

export default ReconciliationReport;


const TableView = ({ data, loading, siteDebit = false }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get("pageNo") || 0;
    const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
    const endDate = searchParams.get("endDate") || "";
    const startDate = searchParams.get("startDate") || "";
    const reportType = searchParams.get("reportType") || "";
    const dateRangeType = searchParams.get("dateRangeType") || "";

    const handleDownload = useCallback(async () => {
        const downloadParams = buildQueryParams({ endDate, startDate });
        try {
            let endPoint = getEndPoint(reportType);
            if (siteDebit) {
                endPoint = `/api/1.0.0/settlement/reconciliation/gs-debit`;
            }
            const response = await fetch(`${API_URL}${endPoint}/download?${downloadParams}`, {
                headers: { "X-JWT-Assertion": localStorage.getItem("token") },
            });
            if (!response.ok) throw new Error("Failed to download file");
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "transaction_history.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error during file download:", error);
        }
    }, [endDate, startDate]);

    return (
        <section>
             {data && data.reconInsight &&
                    <div className='dashboard' style={{ padding: "0px 20px 20px 20px" }}>
                        <div className='transactions-quick-links'>
                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 '>
                                            Total Transaction
                                        </p>
                                        <h6 className='color-primary f-w-700 '>
                                            {data.reconInsight?.totalTransaction != null
                                                ? data.reconInsight.totalTransaction
                                                : 0} Transactions
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiMoneyBill className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 '>
                                            Total Debit Amount
                                        </p>
                                        <h6 className='color-primary f-w-700'>
                                            {APP_CURRENCY}{" "}
                                            {data.reconInsight?.totalAmount != null
                                                ? `${formatAmountValue(data.reconInsight.totalAmount)}/- `
                                                : `0 /-`}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div className="flex center-left responsive-container">
                <ResultsPerPage
                    targetURL={`/reconciliation-report?${buildQueryParams({ endDate, startDate, reportType, dateRangeType, })}&pageNo=0&pageSize=`}
                />
                <ButtonTextIcon
                    onClick={handleDownload}
                    type="button"
                    title={"Download"}
                    icon={<CiInboxIn />}
                    className="responsive-button"
                />
            </div>
            <div className="results">
                <div className="rs-table-holder">
                    <table className="rs-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Account no.</th>
                                <th>Associated acc.</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && data && data.transactionHistoryResponses && data.transactionHistoryResponses.length > 0 && data.transactionHistoryResponses.map((d, i) => (
                                <tr
                                    key={i}
                                    style={{
                                        backgroundColor: d.transactionType === "CREDIT" ? "#EAF8F4" : "#FFEBEB",
                                    }}
                                >
                                    <td>{data.pageNo * data.pageSize + i + 1}</td>
                                    <td>{d.account || "-"}</td>
                                    <td>{d.associateAccount || "-"}</td>
                                    <td className="f-w-700">{d.transactionType === "CREDIT" ? "Credit" : "Debit"}</td>
                                    <td>{APP_CURRENCY} {formatAmountValue(d.transactionAmount)}/-</td>
                                    <td>{formatDateTime(d.transactionTime)}</td>
                                    <td>{d.description}</td>
                                    <td>
                                        {d.transactionReceipt && <div className="flex center-center">
                                            <a href={d.transactionReceipt} target="_blank">
                                                <CiInboxIn />
                                            </a>
                                        </div>}
                                    </td>
                                </tr>
                            ))}
                            {(!data || data?.transactionHistoryResponses?.length === 0) && !loading && (
                                <tr>
                                    <td colSpan={8} style={{ textAlign: "center", padding: "10px" }} >
                                        <p> No records found. </p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {data && <Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
                baseUrl={`/reconciliation-report?${buildQueryParams({ endDate, startDate, reportType, dateRangeType, pageSize })}&`}
            />}
        </section>
    )
} 