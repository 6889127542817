import { useNavigate } from "react-router-dom";
import { CiCircleCheck, CiLocationArrow1, CiCircleX, CiCircleAlert } from "react-icons/ci"; 

const Pagination = ({ totalRecords, pageNo, pageSize, baseUrl }) => {
	const navigate = useNavigate();
	const maxPagesToShow = 5; // Maximum number of page numbers to display
	const totalPages = Math.ceil(totalRecords / pageSize);

	const handlePageClick = (pageIndex) => {
		if (pageIndex !== pageNo && pageIndex >= 0 && pageIndex < totalPages) {
			navigate(`${baseUrl}pageNo=${pageIndex}&pageSize=${pageSize}`);
		}
	};

	const getPaginationUI = () => {
		if (totalPages > 0) {
			let startPage = Math.max(0, pageNo - Math.floor(maxPagesToShow / 2));
			let endPage = startPage + maxPagesToShow - 1;

			if (endPage >= totalPages) {
				endPage = totalPages - 1;
				startPage = Math.max(0, endPage - maxPagesToShow + 1);
			}

			let allData = [];

			// Previous page button (disabled if on the first page)
			allData.push(
				<li
					key="prev"
					onClick={() => handlePageClick(pageNo - 1)}
					disabled={pageNo === 0}
				>
					{pageNo ? (
						"<"
					) : pageNo === 0 ? (
						"<"
					) : (
						"<"
					)}
				</li>
			);

			// Page numbers
			for (let index = startPage; index <= endPage; index++) {
				allData.push(
					<li
						key={index}
						className={`${index === pageNo ? "active" : ""}`}
						onClick={() => handlePageClick(index)}
					>
						{index + 1}
					</li>
				);
			}

			// Ellipsis after the last page number
			if (endPage < totalPages - 1) {
				allData.push(
					<li key="end-ellipsis">...</li>
				);
			}

			// Next page button
			allData.push(
				<li
					key="next"
					onClick={() => handlePageClick(pageNo + 1)}
					disabled={pageNo === totalPages - 1 }
				>
					{pageNo ? (
						">"
					) : pageNo === 0 ? (
						">"
					) : (
						">"
					)}
				</li>
			);

			return allData;
		} else {
			return [];
		}
	};

	return <ul className="rs-pagination">{getPaginationUI()}</ul>;
};

export default Pagination;