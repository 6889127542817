import { CiCircleRemove, CiCirclePlus, CiBarcode } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { NavLink, useLocation } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { useEffect, useState, useRef } from "react";
import { API_URL, BASE_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";
import RSModal from "../../components/ui/RSModal";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Client = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const { setSegmentTitle } = useUI();
	const userRole = decodeRolesFromToken()[0];


	const callListApi = async () => {
		setLoading(true);
		const pageNo = new URLSearchParams(location.search).get("pageNo");
		const pageSize = new URLSearchParams(location.search).get("pageSize");
		try {
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/client?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callListApi();
		setSegmentTitle("Client");
	}, [location.search]);

	return (
		<>
			<PageTitle title='Client' />
			<FullPageLoader isActive={loading} />
			<section>
				<section >
					<div
						className='flex center-left responsive-container'
						>
						<ResultsPerPage
							targetURL={`/client?pageNo=0&pageSize=`}
						/>
						{(userRole === 1 || userRole === 2) && (
						<div className="responsive-button">
						<NavLink to='/client/add'>
							<ButtonTextIcon
								title='Add Client'
								icon={<CiCirclePlus />}
							/>
						</NavLink>
					</div>
					)}
					</div>
				</section>

				<div className='results'>
					<div className='rs-table-holder'>
						<table className='rs-table'>
							<thead>
								<tr>
									<th>#</th>
									<th>Client Name</th>
									<th>Admin's Name</th>
									<th>Admin's Email</th>
									<th>Address</th>
								</tr>
							</thead>
							{data && (
								<tbody>
									{data.clients && data.clients.map((d, i) => (
										<tr key={i}>
											<td>
												{data.pageNo * data.pageSize +
													i +
													1}
											</td>
											<td>{d.name}</td>
											<td>{d.adminName}</td>
											<td>{d.adminEmail}</td>
											<td>{d.address}</td>
										</tr>
									))}
									{(!data.clients || data.clients?.length === 0 )&& !loading && (
										<tr>
											<td colSpan={5} style={{ textAlign: "center", padding: "10px" }} >
												<p> No records found. </p>
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl='/client?'
					/>
				)}
			</section>
		</>
	);
};


export default Client;
