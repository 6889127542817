import { useEffect, useState, useCallback } from "react";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiSearch, CiMoneyBill, CiFileOn, CiRedo, CiFilter, CiInboxIn, CiCircleRemove, CiCircleCheck } from "react-icons/ci";
import { API_URL, APP_CURRENCY, DEFAULT_PER_PAGE } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
import { useUI } from "../../context/ui";
import { useFetch } from "../../hooks/useFetch";
import { formatAmountValue, formatDateTime, decodeRolesFromToken } from "../../utilities/helpers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import RSModal from "../../components/ui/RSModal";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon"; 

const buildQueryParams = (params) => Object.entries(params)
  .filter(([_, value]) => value !== "")
  .map(([key, value]) => `${key}=${value}`)
  .join("&");

function formatFiltersAsReadableString(filters) {
    return Object.entries(filters)
        .filter(([key, value]) => value !== undefined && value !== null && value !== '')
        .map(([key, value]) => {
            let readableKey;
            switch (key) {
                case 'type':
                    readableKey = 'Type';
                    break;
                case 'fromDate':
                    readableKey = 'From Date';
                    break;
                case 'toDate':
                    readableKey = 'To Date';
                    break;
                case 'clientId':
                    readableKey = 'Client';
                    break;
                case 'gasStationId':
                    readableKey = 'Gas Station';
                    break;
                case 'filterType':
                    readableKey = 'Filter Type';
                    break;
                case 'accountNumber':
                    readableKey = 'Account Number';
                    break;
                case 'associateAccount':
                    readableKey = 'Associate Account';
                    break;
                default:
                    readableKey = key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
            }
            return `${readableKey}`;//: ${value}
        })
        .join(', ');
}

const fetchData = async (endpoint, setState , setLoading) => {
  setLoading(true);
  try {
    const response = await fetch(`${API_URL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        "X-JWT-Assertion": localStorage.getItem("token"),
      },
    });
    const data = await response.json();
    setState(data.data || []);
  } catch (e) {
    console.error("Error fetching data", e);
    setState([]);
  } finally {
    setLoading(false);
  }
};

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [downloadShow, setDownloadShow] = useState(false);
  const [userClientId , setUserClientId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { setSegmentTitle } = useUI();
  const userRole = decodeRolesFromToken()[0];

  const searchParams = new URLSearchParams(location.search);
  const pageNo = searchParams.get("pageNo") || 0;
  const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
  const fromDate = searchParams.get("fromDate") || "";
  const toDate = searchParams.get("toDate") || "";
  const accountNumber = searchParams.get("accountNumber") || "";
  const associateAccount = searchParams.get("associateAccount") || "";
  const gasStationId = searchParams.get("gasStationId") || "";
  const clientId = searchParams.get("clientId") || "";
  const type = searchParams.get("type") || "";
  const filterType = searchParams.get("filterType") || "";

  const { data } = useFetch(API_URL + "/api/1.0.0/user/profile", { method: "GET", });

  useEffect(() => {
    if (data) { 
      setDownloadShow(data.data.downloadReport || false); 
      setUserClientId(data.data.clientId || "");
    }
  }, [data]);

  const callListApi = useCallback(() => {
    const queryParams = buildQueryParams({
      sortOrder: "desc",
      type,
      endDate: toDate,
      startDate: fromDate,
      clientId: userRole === 6 ? userClientId : clientId,
      gasStationId,
      accountNumber,
      associateAccount,
      ...(userRole !== 4 && { pageNo, pageSize }),
    });
    fetchData(`/ocpl/transaction-history?${queryParams}`, (data) => {
      setListData(data);
      setFilteredData(data.transactionHistoryResponses || []);
    }, setLoading);
  }, [userRole, pageNo, pageSize, fromDate, toDate, clientId, gasStationId, type, associateAccount, accountNumber]);

  useEffect(() => {
    callListApi();
    setSegmentTitle("Transactions");
  }, [location.search]);

  const handleDownload = useCallback(async () => {
    const downloadParams = buildQueryParams({
      sortOrder: "desc",
      type,
      endDate: toDate,
      startDate: fromDate,
      clientId: userRole === 6 ? userClientId : clientId,
      gasStationId,
      accountNumber,
      associateAccount,
    });

    try {
      const response = await fetch(`${API_URL}/ocpl/transaction-history/download?${downloadParams}`, {
        headers: { "X-JWT-Assertion": localStorage.getItem("token") },
      });
      if (!response.ok) throw new Error("Failed to download file");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "transaction_history.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during file download:", error);
    }
  }, [fromDate, toDate, clientId, gasStationId, accountNumber, associateAccount, type]);
 

  return (
    <section className="pos-rel">
      <PageTitle title="Transactions" />
      <FullPageLoader isActive={loading} />

      {![4].includes(userRole) && (
        <div className='dashboard' style={{ padding: "0px 20px 20px 20px" }}>
          <div className='transactions-quick-links'>
            <div className='one flex center-left'>
              <div className='flex center-left' style={{ gap: "10px" }}>
                <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                <div>
                  <p className='color-faded font-size-12 f-w-400 '>
                    Total Transaction
                  </p>
                  <h6 className='color-primary f-w-700 '>
                    {listData?.transactionDetails?.totalTransaction != null
                      ? listData.transactionDetails.totalTransaction
                      : 0} Transactions
                  </h6>
                </div>
              </div>
            </div>

            <div className='one flex center-left'>
              <div className='flex center-left' style={{ gap: "10px" }}>
                <CiMoneyBill className='color-secondary' style={{ height: "40px", width: "auto" }} />
                <div>
                  <p className='color-faded font-size-12 f-w-400 '>
                    Total Debit Amount
                  </p>
                  <h6 className='color-primary f-w-700'>
                    {APP_CURRENCY}{" "}
                    {listData?.transactionDetails?.totalDebitAmount != null
                      ? `${formatAmountValue(listData.transactionDetails.totalDebitAmount)}/- `
                      : `0 /-`}
                  </h6>
                </div>
              </div>
            </div>
            <div className='one flex center-left'>
              <div className='flex center-left' style={{ gap: "10px" }}>
                <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                <div>
                  <p className='color-faded font-size-12 f-w-400 '>
                    Total Debit Transaction
                  </p>
                  <h6 className='color-primary f-w-700 '>
                    {listData?.transactionDetails?.totalDebitCount != null
                      ? listData.transactionDetails.totalDebitCount
                      : 0} Transactions
                  </h6>
                </div>
              </div>
            </div>
            <div className='one flex center-left'>
              <div className='flex center-left' style={{ gap: "10px" }}>
                <CiMoneyBill className='color-secondary' style={{ height: "40px", width: "auto" }} />
                <div>
                  <p className='color-faded font-size-12 f-w-400 '>
                    Total Credit Amount
                  </p>
                  <h6 className='color-primary f-w-700 '>
                    {APP_CURRENCY}{" "}
                    {listData?.transactionDetails?.totalCreditAmount != null
                      ? `${formatAmountValue(listData.transactionDetails.totalCreditAmount)}/- `
                      : `0 /-`}
                  </h6>
                </div>
              </div>
            </div>
            <div className='one flex center-left'>
              <div className='flex center-left' style={{ gap: "10px" }}>
                <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                <div>
                  <p className='color-faded font-size-12 f-w-400'>
                    Total Credit Transaction
                  </p>
                  <h6 className='color-primary f-w-700 '>
                    {listData?.transactionDetails?.totalCreditCount != null
                      ? listData.transactionDetails.totalCreditCount
                      : 0} Transactions
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {userRole !== 4 && (
        <>
          <div className="flex center-left responsive-container">
            <ResultsPerPage
               targetURL={`/transactions?${buildQueryParams({type, fromDate, toDate, clientId, gasStationId, filterType, accountNumber, associateAccount})}&pageNo=0&pageSize=`}
            />
            <div className="responsive-button">
              <div className="flex center-right" style={{ gap: "10px" }} >
                {(![7, 4].includes(userRole) || (userRole === 7 && downloadShow)) &&
                  <ButtonTextIcon
                    onClick={handleDownload}
                    type="button"
                    title={"Download"}
                    icon={<CiInboxIn />}
                  />}
                {![4].includes(userRole) &&
                  <RSModal
                    selector={<ButtonTextIcon
                      type="button"
                      title="Filter"
                      icon={<CiFilter />}
                    />}
                    content={<FilterContent 
                      setLoading={setLoading} 
                      loading={loading} 
                      userClientId={userClientId}
                      initialValues={{
                        toDate,
                        fromDate,
                        filterType: filterType || "CLIENT_AND_GAS_STATION",
                        clientId,
                        gasStationId,
                        accountNumber,
                        associateAccount,
                        type,
                      }}
                     />}
                  />
                }
              </div>
            </div>
          </div>
        </>
      )}

      {formatFiltersAsReadableString({
        toDate,
        fromDate,
        clientId,
        gasStationId,
        accountNumber,
        associateAccount,
        type,
      }) && <div className="flex center-right responsive-container" > <p class="title f-w-700 color-primary" >Filter(s):  {formatFiltersAsReadableString({
        toDate,
        fromDate,
        clientId,
        gasStationId,
        accountNumber,
        associateAccount,
        type,
      })}</p></div>}

      <div className="results">
        <div className="rs-table-holder">
          {filteredData && (
            <table className="rs-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account no.</th>
                  <th>Associated acc.</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {!loading && filteredData.length > 0 && filteredData.map((d, i) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: d.transactionType === "CREDIT" ? "#EAF8F4" : "#FFEBEB",
                    }}
                  >
                    <td>
                      <div className="flex center-left" style={{ gap:"10px"}}>
                        <div>
                          {listData.pageNo * listData.pageSize + i + 1}
                        </div>
                        {![4,10,7].includes(userRole) && d.settlementId &&
                          <div>
                            <CiCircleCheck />
                          </div>
                        }
                      </div>
                    </td>
                    <td>{d.account || "-"}</td>
                    <td>{d.associateAccount || "-"}</td>
                    <td className="f-w-700">{d.transactionType === "CREDIT" ? "Credit" : "Debit"}</td>
                    <td>{APP_CURRENCY} {formatAmountValue(d.transactionAmount)}/-</td>
                    <td>{formatDateTime(d.transactionTime)}</td>
                    <td>{d.description}</td>
                    <td>
                      <div className="flex center-center">
                      {d.transactionReceipt &&  
                          <div><a href={d.transactionReceipt} target="_blank">
                            <CiInboxIn />
                          </a></div>
}
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredData.length === 0 && !loading && (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center", padding: "10px" }} >
                      <p> No records found. </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {listData && userRole !== 4 && (
        <Pagination
          totalRecords={listData.totalRecords}
          pageNo={listData.pageNo}
          pageSize={listData.pageSize}
          baseUrl={`/transactions?${buildQueryParams({ type, fromDate, toDate, clientId, gasStationId, filterType, accountNumber, associateAccount })}&`}
        />
      )}
    </section>
  );
};


const FilterContent = ({ setLoading,  initialValues , loading ,  setShow }) => {
  const userRole = decodeRolesFromToken()[0];
  const location = useLocation();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");
  const [userClientId, setUserClientId] = useState("");
  const [clientData, setClientData] = useState(null);
  const [gasStationsData, setGasStationsData] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;

  const { data } = useFetch(API_URL + "/api/1.0.0/user/profile", { method: "GET", });

  useEffect(() => {
    if (data) { 
      setUserClientId(data.data.clientId || "");
    }
  }, [data]);

  useEffect(() => {
    fetchData("/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc", setClientData, setLoading);
  }, [fetchData]);

  useEffect(() => {
    if (clientId || initialValues?.clientId || userClientId) {
      fetchData(`/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc&clientID=${clientId || initialValues?.clientId || userClientId}`, setGasStationsData, setLoading);
    }
  }, [clientId,userClientId, fetchData]);

  const validationSchema = Yup.object({
    fromDate: Yup.string()
      .nullable() // Allow null or empty values since it's not required
      .test('is-not-future-date', 'From date cannot be in the future', function (value) {
        if (!value) return true; // Skip validation if no date is selected
        const selectedDate = new Date(value);
        const today = new Date();
        return selectedDate <= today;
      })
      .test('is-before-toDate', 'From date cannot be after To date', function (value) {
        const { toDate } = this.parent;
        if (!value || !toDate) return true; // Skip validation if one or both dates are empty
        const fromDate = new Date(value);
        const endDate = new Date(toDate);
        return fromDate <= endDate;
      }),

    toDate: Yup.string()
      .nullable() // Allow null or empty values since it's not required
      .test('is-not-future-date', 'To date cannot be in the future', function (value) {
        if (!value) return true; // Skip validation if no date is selected
        const selectedDate = new Date(value);
        const today = new Date();
        return selectedDate <= today;
      })
      .test('is-after-fromDate', 'To date cannot be before From date', function (value) {
        const { fromDate } = this.parent;
        if (!value || !fromDate) return true; // Skip validation if one or both dates are empty
        const endDate = new Date(value);
        const startDate = new Date(fromDate);
        return endDate >= startDate;
      }),

    clientId: Yup.string(),
    filterType: Yup.string(),
    gasStationId: Yup.string(),
    accountNumber: Yup.string(),
    associateAccount: Yup.string(),
    type: Yup.string(),
  });
 

  return (<div className='pos-rel' style={{ width: "480px", padding: "40px" }}>
    <CiCircleRemove
      className='cursor-pointer transition hover-color-primary'
      onClick={() => setShow(false)}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        fontSize: "32px",
      }}
    />
    <h4 className='color-primary'>Filter </h4>
    <br />
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={ ( values) => {
        try {
          console.log(values);
          const queryParams = buildQueryParams({
            pageNo: 0,
            pageSize,
            toDate: values.toDate,
            fromDate: values.fromDate,
            filterType: values.filterType,
            clientId: values.clientId ,
            gasStationId: values.gasStationId,
            accountNumber: values.accountNumber,
            associateAccount: values.associateAccount,
            type: values.type,
          })
          navigate(`/transactions?${queryParams}`);
          setShow();
        } catch (e) {
          console.log(e);
        }
      }}>
      {({
        values,
        errors,
        touched,
        status,
        validateForm,
        setFieldValue,
        resetForm,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <div>

            <div className="flex center-center" style={{ gap: "10px" }} >
            <div style={{ width: "100%" }} >
                <FieldTitle title={"Form Date"} />
                <Field
                  name="fromDate"
                  type="date" 
                  className={"col-1-1 " + (errors.fromDate && touched.fromDate ? " error" : "")}
                />
                <ErrorMessage
															name='fromDate'
															component='p'
															className='error-messages'
														/>
              </div>
              <div style={{ width: "100%" }} >
                <FieldTitle title={"To Date"} />
                <Field
                  name="toDate"
                  type="date" 
                  className={"col-1-1 " + (errors.toDate && touched.toDate ? " error" : "")}
                />
                <ErrorMessage
															name='toDate'
															component='p'
															className='error-messages'
														/>
              </div>
            </div>
            
            <br />

            {![10, 7, 4].includes(userRole) &&
              <>

                <div className="flex center-center" style={{ gap: "10px" }}>
                  <div style={{ width: "100%" }}>
                    <FieldTitle title={"Transaction Type"} />
                    <Field
                      name="type"
                      as='select'
                      className={"col-1-1 " + (errors.type && touched.type ? " error" : "")}
                    >
                      <option value="">All Type</option>
                      <option value="CREDIT">Credit</option>
                      <option value="DEBIT">Debit</option>
                    </Field>
                  </div>

                  <div style={{ width: "100%" }}>
                    <FieldTitle title={"Filter By"} />
                    <Field
                      name="filterType"
                      as='select'
                      className={"col-1-1 " + (errors.type && touched.type ? " error" : "")}
                      onChange={(event) => {
                        setFieldValue("filterType", event.target.value);
                        setFieldValue("clientId", "");
                        setFieldValue("gasStationId", "");
                        setFieldValue("accountNumber", "");
                      }}
                    >
                      <option value="CLIENT_AND_GAS_STATION">By Client & Site</option>
                      {![6,8,3].includes(userRole) && <option value="ACCOUNT_NO">By Account no.</option> }
                    </Field>
                  </div>
                </div>

                <br />

                {values.filterType === "CLIENT_AND_GAS_STATION" &&
                  <div className="flex center-center" style={{ gap: "10px" }}>
                    <div style={{ width: "100%" }}>
                      <FieldTitle title={"Client"} />
                      <Field
                        name="clientId"
                        as='select'
                        disabled={[6, 8, 3].includes(userRole)}
                        className={"col-1-1 " + (errors.type && touched.type ? " error" : "")}
                        onChange={(event) => {
                          setFieldValue("clientId", event.target.value);
                          setFieldValue("gasStationId", "");
                          setClientId(event.target.value)
                        }}
                      >
                        {/* <option value="">All Client</option> */}
                        {![6, 8, 3].includes(userRole)  && <option value="">All Client</option>}
                        {clientData?.clients?.map((client, i) => {
                          if ([6, 8, 3].includes(userRole)) {
                            if(userClientId == client.id){
                              return <option key={i} value={client.id}>{client.name}</option>
                            }
                          }else{
                            return <option key={i} value={client.id}>{client.name}</option>
                          }
                        })}
                      </Field>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FieldTitle title={"Site"} />
                      <Field
                        name="gasStationId"
                        as='select'
                        className={"col-1-1 " + (errors.type && touched.type ? " error" : "")}
                      >
                        <option value="">{loading ? "Loading..." : "All Site"}</option>
                        {gasStationsData?.gasStations?.map((station, i) => (
                          <option key={i} value={station.id}>{station.name}</option>
                        ))}
                      </Field>
                    </div>
                  </div>
                }

                {values.filterType === "ACCOUNT_NO" &&
                  <div className="flex center-center" style={{ gap: "10px" }}>
                    <div style={{ width: "100%" }}>
                      <FieldTitle title={"Account No."} />
                      <Field
                        name="accountNumber"
                        placeholder="Enter account no. *"
                        type='text'
                        className={"col-1-1 " + (errors.accountNumber && touched.accountNumber ? " error" : "")}
                      />
                    </div>
                  </div>
                }

                <br />
              </>
            }
         
            <div className="flex center-center" style={{ gap: "10px" }}>
              <div style={{ width: "100%" }}>
                <FieldTitle title={"Associate Account No."} />
                <Field
                  name="associateAccount"
                  placeholder="Enter associate acc no. *"
                  type='text'
                  className={"col-1-1 " + (errors.associateAccount && touched.associateAccount ? " error" : "")}
                />
              </div>
            </div>

            <br />

            <div className="flex center-left" style={{ gap: "10px" }} >
              <ButtonLightTextIcon
                type="button"
                title={"Reset"}
                onClick={() => {
                  resetForm();
                  const queryParams = buildQueryParams({
                    pageNo: 0,
                    pageSize,
                  });
                  navigate(`/transactions?${queryParams}`);
                }}
                icon={<CiRedo />}
              />
              <ButtonTextIcon
                type="submit"
                title={"Search"}
                icon={<CiCircleCheck />}
                className="responsive-button"
              />
            </div>

          </div>
        </Form>
      )}
    </Formik>
  </div>)
}

const FieldTitle = ({ title }) => {
  return (
    <p
      className="title f-w-700 color-primary"
      style={{ marginBottom: "5px", fontSize: "14px" }}
    >
      {title}
    </p>
  );
};

export default TransactionHistory;

