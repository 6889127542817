import { CiCircleRemove, CiInboxIn, CiRedo, CiSearch } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken, formatDateTime } from "../../utilities/helpers";
import { useCallback, useEffect, useState } from "react";
import {
  API_URL,
  APP_CURRENCY,
  DEFAULT_PER_PAGE,
} from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SearchBar from "../../components/ui/SearchBar";
import { AiOutlineCheckCircle } from "react-icons/ai";
import RSModal from "../../components/ui/RSModal";
import { color } from "chart.js/helpers";
import LoaderIcon from "../../components/ui/LoaderIcon";
import { FaArrowLeftLong } from "react-icons/fa6";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";

const buildQueryParams = (params) =>
  Object.entries(params)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const CustomerList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const {
    setSegmentTitle,
    setNewNotification,
    setShowNotification,
    addNotification,
    addToastMessage,
  } = useUI();
  const userRole = decodeRolesFromToken()[0];
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const pageNo = new URLSearchParams(location.search).get("pageNo");
  const pageSize = new URLSearchParams(location.search).get("pageSize");
  const filterType =
    new URLSearchParams(location.search).get("filterType") || "";
  const search = new URLSearchParams(location.search).get("search") || "";
  const startDate = new URLSearchParams(location.search).get("startDate") || "";
  const endDate = new URLSearchParams(location.search).get("endDate") || "";
  const statusID = new URLSearchParams(location.search).get("statusID") || "";
  const minBalance =
    new URLSearchParams(location.search).get("minBalance") || "";
  const maxBalance =
    new URLSearchParams(location.search).get("maxBalance") || "";
  const [initialValues, setInitialValues] = useState({
    filterType: filterType || "",
    search:
      filterType === "balance" ? "" : filterType === "date" ? "" : search || "",
    minBalance: filterType === "balance" ? parseFloat(minBalance) || "" : "",
    maxBalance: filterType === "balance" ? parseFloat(maxBalance) || "" : "",
    startDate: filterType === "startDate" ? new Date(startDate) : "",
    endDate: filterType === "endDate" ? new Date(endDate) : "",
  });

  const resetFilters = () => {
    const queryParams = buildQueryParams({
      filterType: "",
      searchType: "",
      search: "",
      pageNo: 0,
      pageSize,
      minBalance: "",
      maxBalance: "",
      startDate: "",
      endDate: "",
    });
    navigate(`/customers?${queryParams}`);
  };

  const callListApi = async () => {
    setLoading(true);

    let apiUrl = `${API_URL}/api/1.0.0/user?roleID=5`;

    const searchType =
      filterType === "name"
        ? 1
        : filterType === "email"
        ? 2
        : filterType === "accountNumber"
        ? 3
        : filterType === "balance"
        ? 4
        : filterType === "date"
        ? 5
        : filterType === "status"
        ? 6
        : 0;

    if (filterType === "balance") {
      apiUrl += `&searchType=${searchType}&minBalance=${minBalance}&maxBalance=${maxBalance}&pageNo=${pageNo}&pageSize=${pageSize}`;
    } else if (filterType === "date") {
      apiUrl += `&searchType=${searchType}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNo}&pageSize=${pageSize}`;
    } else if (filterType) {
      apiUrl += `&searchType=${searchType}&search=${search}&pageNo=${pageNo}&pageSize=${pageSize}`;
    } else {
      apiUrl += `&pageNo=${pageNo}&pageSize=${pageSize}`;
    }

    try {
      console.log("ApiRUL: ", apiUrl);
      const resp = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });

      if (resp.ok) {
        const response = await resp.json();
        setData(response.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async (id, status, setShow) => {
    try {
      const updatedStatus = status === 1 ? 2 : 1;
      console.log("Updated Status: ", updatedStatus);

      setLoading(true);
      console.log(id, updatedStatus);

      const response = await fetch(`${API_URL}/api/1.0.0/user/${id}/status`, {
        method: "PUT",
        headers: {
          "X-JWT-Assertion": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ statusID: updatedStatus }),
      });

      console.log("Response: ", response);

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.responseDescription ||
          "Something went wrong. Please try again!";
        addToastMessage({
          status: false,
          message: errorMessage,
        });
        return;
      }

      addToastMessage({
        status: true,
        message: "You have successfully updated the user status!",
      });
      setNewNotification(true);
      setShowNotification(false);
      addNotification({
        id: Date.now(),
        message: "User status updated successfully!",
        status: true,
        archive: false,
      });

      await callListApi();
      console.log("Status updated successfully!");
    } catch (error) {
      console.error("Error occurred:", error);
      addNotification({
        id: Date.now(),
        message: "Something went wrong while updating the user status.",
        status: false,
        archive: false,
      });
    } finally {
      setLoading(false);
      setShow(false);
    }
  };

  const Confirmation = ({ userId, status, setShow }) => {
    return (
      <>
        <div className="pos-rel" style={{ width: "480px", padding: "40px" }}>
          <CiCircleRemove
            className="cursor-pointer transition hover-color-primary"
            onClick={() => setShow(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "32px",
            }}
          />
          <p className="color-tertiary">
            Are you sure You want want to{" "}
            <strong>{status === 1 ? "deactivate" : "activate"}</strong> the
            user?
          </p>
          <div
            className="container flex center-center"
            style={{
              gap: "20px",
              padding: "12px",
            }}
          >
            <ButtonTextIcon
              title="Confirm"
              disabled={loading}
              icon={!loading ? <AiOutlineCheckCircle /> : <LoaderIcon />}
              onClick={() => handleConfirm(userId, status, setShow)}
            />
            <ButtonLightTextIcon
              title="Cancel"
              icon={<FaArrowLeftLong />}
              onClick={() => setShow(false)}
            />
          </div>
        </div>
      </>
    );
  };

  const handleDownload = useCallback(async () => {
    try {
      const searchTypeMap = {
        name: 1,
        email: 2,
        accountNumber: 3,
        balance: 4,
        date: 5,
      };

      const { filterType, search, minBalance, maxBalance, startDate, endDate } =
        initialValues;

      const searchType = searchTypeMap[filterType] || "";

      const downloadParams = buildQueryParams({
        search: search || "",
        searchType: searchType,
        minBalance: minBalance || "",
        maxBalance: maxBalance || "",
        startDate: startDate || "",
        endDate: endDate || "",
      });

      console.log("Download Params:", downloadParams);

      const endPoint = `/api/1.0.0/user/customer`;

      const response = await fetch(
        `${API_URL}${endPoint}/download?${downloadParams}`,
        {
          method: "GET",
          headers: {
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );

      console.log("Response:", response);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "CustomerData.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error during file download:", error);
    }
  }, [initialValues]);

  useEffect(() => {
    callListApi();
    setSegmentTitle("Customers");
  }, [location.search]);

  useEffect(() => {
    setInitialValues({
      filterType: filterType || "",
      search: filterType === "balance" ? "" : search || "",
      minBalance: filterType === "balance" ? minBalance || "" : "",
      maxBalance: filterType === "balance" ? maxBalance || "" : "",
      startDate: filterType === "date" && startDate ? startDate : "",
      endDate: filterType === "date" && endDate ? endDate : "",
    });
  }, [location.search]);

  const FormValidation = Yup.object().shape({
    startDate: Yup.date().nullable().typeError("Invalid Start Date"),
    endDate: Yup.date()
      .nullable()
      .typeError("Invalid End Date")
      .test(
        "endDateCheck",
        "End Date cannot be earlier than Start Date",
        function (value) {
          const { startDate } = this.parent;
          if (value && startDate) {
            return value >= startDate;
          }
          return true;
        }
      ),
    filterType: Yup.string(),
    minBalance: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue !== undefined && originalValue !== null
          ? String(originalValue).trim() === ""
            ? null
            : value
          : null
      )
      .min(0, "Min Balance cannot be negative")
      .test(
        "is-required-when-balance",
        "Min Balance is required when Filter Type is Balance",
        function (value) {
          const { filterType } = this.parent;
          return filterType !== "balance" || value !== null;
        }
      ),
    maxBalance: Yup.number()
      .nullable()
      .transform((value, originalValue) => {
        if (
          originalValue === undefined ||
          originalValue === null ||
          String(originalValue).trim() === ""
        ) {
          return null;
        }
        return value;
      })
      .min(0, "Max Balance cannot be negative")
      .test(
        "is-greater-or-equal",
        "Max Balance must be greater than or equal to Min Balance",
        function (value) {
          const { minBalance, filterType } = this.parent;
          return (
            filterType !== "balance" ||
            value === null ||
            minBalance === null ||
            value >= minBalance
          );
        }
      ),
  });
 

  
  // const fetchUserData = async (statusID) => {
  //   console.log("statusId: ", statusID)
  //   const url = `http://localhost:8080/api/1.0.0/user?pageSize=10&roleID=5&searchType=6&statusID=${statusID}`;
    
  //   try {
  //     const response = await fetch(`${API_URL}/api/1.0.0/user`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-JWT-Assertion": localStorage.getItem("token"),
  //       },
  //     });
  //     const data = await response.json();
  //     console.log("API Response:", data);
  //     // Handle the response data as needed (e.g., set data to state)
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  

  return (
    <>
      <PageTitle title="Customers" />
      <FullPageLoader isActive={loading} />
      <section>
        <div className="flex center-left responsive-container">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={FormValidation}
              enableReinitialize={true}
              onSubmit={async (values) => {
                console.log("Submitted values", values);
                const queryParams = buildQueryParams({
                  filterType: values.filterType,
                  search: values.search,
                  pageNo: 0,
                  pageSize,
                  minBalance: values.minBalance,
                  maxBalance: values.maxBalance,
                  startDate: values.startDate,
                  endDate: values.endDate,
                });

                navigate(`/customers?${queryParams}`);
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form>
                  <div
                    className="flex center-left responsive-container"
                    style={{
                      gap: "12px",
                      marginBottom: "-20px",
                      marginLeft: "-18px",
                    }}
                  >
                    <div
                      className={`responsive-select `}
                      style={{
                        width: "180px",
                      }}
                    >
                      <ResultsPerPage
                        targetURL={`/customers?${buildQueryParams({
                          filterType,
                          search,
                          minBalance,
                          maxBalance,
                          startDate,
                          endDate,
                        })}&pageNo=0&pageSize=`}
                      />
                      <p>&nbsp;</p>
                    </div>
                    <div>
                      <Field
                        style={{
                          width: "170px",
                        }}
                        as="select"
                        name="filterType"
                        className={`responsive-select ${
                          errors.filterType && touched.filterType ? "error" : ""
                        }`}
                        onChange={(event) => {
                          setFieldValue("filterType", event.target.value);
                          setFieldValue("search", "");
                          setFieldValue("minBalance", "");
                          setFieldValue("maxBalance", "");
                          if (event.target.value === "date") {
                            setFieldValue("startDate", "");
                            setFieldValue("endDate", "");
                          }
                          // if ( event.target.value === "status") {
                          //   setFieldValue("active", "");
                          //   setFieldValue("inActive", "");
                          // }
                        }}
                      >
                        <option value="">Select Filter</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="accountNumber">Account Number</option>
                        <option value="balance">Balance</option>
                        {/* <option value="date">Date</option> */}
                        {/* <option value="status">Status</option> */}
                      </Field>
                      <p>&nbsp;</p>
                    </div>
                    {values.filterType &&
                      (values.filterType === "balance" ? (
                        <div
                          className="balance-filter flex "
                          style={{
                            gap: "12px",
                            width: "300px",
                          }}
                        >
                          <div
                            style={{
                              margin: "-2px",
                            }}
                          >
                            <Field
                              type="number"
                              name="minBalance"
                              placeholder="Min Balance"
                              className={`col-1-1 ${
                                errors.minBalance && touched.minBalance
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.minBalance && touched.minBalance ? (
                                errors.minBalance
                              ) : (
                                <p>&nbsp;</p>
                              )}
                            </p>
                          </div>
                          <div
                            style={{
                              margin: "-2px",
                            }}
                          >
                            <Field
                              type="number"
                              name="maxBalance"
                              placeholder="Max Balance"
                              className={`col-1-1 ${
                                errors.maxBalance && touched.maxBalance
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.maxBalance && touched.maxBalance ? (
                                errors.maxBalance
                              ) : (
                                <p>&nbsp;</p>
                              )}
                            </p>
                          </div>
                        </div>
                      ) 
                      // : values.filterType === "status" ? (
                      //   <div
                      //     style={{
                      //       marginBottom: "23px",
                      //     }}
                      //   >
                      //     <Field
                      //       style={{ width: "120px" }}
                      //       className="responsive-select"
                      //       as="select"
                      //       name="status" // Changed to 'status' to avoid overriding filterType
                      //       onChange={(event) => {
                      //         const selectedStatus = event.target.value;
                      //         let statusID = null;

                      //         if (selectedStatus === "active") {
                      //           statusID = 1; // Active status ID
                      //         } else if (selectedStatus === "inActive") {
                      //           statusID = 2; // Inactive status ID
                      //         }

                      //         // Call fetchUserData if a status is selected
                      //         if (statusID) {
                      //           fetchUserData(statusID);
                      //         }

                      //         // Optionally, clear the other form fields when status is selected
                      //         setFieldValue(
                      //           "active",
                      //           selectedStatus === "active" ? true : false
                      //         );
                      //         setFieldValue(
                      //           "inActive",
                      //           selectedStatus === "inActive" ? true : false
                      //         );
                      //       }}
                      //     >
                      //       <option value="">All</option>
                      //       <option value="active">Active</option>
                      //       <option value="inActive">Inactive</option>
                      //     </Field>
                      //   </div>
                      // ) 
                      : values.filterType === "date" ? (
                        <div
                          className="date-filter flex"
                          style={{ gap: "12px" }}
                        >
                          <div style={{ margin: "-2px" }}>
                            <Field
                              type="date"
                              name="startDate"
                              className={`col-1-1 ${
                                errors.startDate && touched.startDate
                                  ? "error"
                                  : ""
                              }`}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.startDate && touched.startDate ? (
                                errors.startDate
                              ) : (
                                <p>&nbsp;</p>
                              )}
                            </p>
                          </div>
                          <div style={{ margin: "-2px" }}>
                            <Field
                              type="date"
                              name="endDate"
                              className={`col-1-1 ${
                                errors.endDate && touched.endDate ? "error" : ""
                              }`}
                            />
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.endDate && touched.endDate ? (
                                errors.endDate
                              ) : (
                                <p>&nbsp;</p>
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="balance-filter" style={{ gap: "12px" }}>
                          <Field
                            type="text"
                            name="search"
                            placeholder={`Search by ${
                              values.filterType || "..."
                            }`}
                            className={`col-1-1 ${
                              errors.search && touched.search ? "error" : ""
                            }`}
                          />
                          <p>&nbsp;</p>
                        </div>
                      ))}
                    <div className="flex " style={{ gap: "12px" }}>
                      <div>
                        <ButtonTextIcon
                          title="Search"
                          type="submit"
                          icon={<CiSearch />}
                          disabled={
                            (!values.search &&
                              values.filterType !== "balance" &&
                              values.filterType !== "date") ||
                            (values.filterType === "balance" &&
                              values.minBalance === "" &&
                              values.maxBalance === "") ||
                            (values.filterType === "date" &&
                              !values.startDate &&
                              !values.endDate)
                          }
                        />
                        <p>&nbsp;</p>
                      </div>

                      <div className="responsive-button">
                        <ButtonTextIcon
                          title="Reset"
                          type="button"
                          onClick={() => {
                            resetForm();
                            resetFilters();
                          }}
                          icon={<CiRedo />}
                        />
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {userRole === 1 && (
            <div className="responsive-button">
              <ButtonTextIcon
                onClick={handleDownload}
                type="button"
                title={"Download"}
                icon={<CiInboxIn />}
              />
            </div>
          )}
        </div>

        <div className="results">
          <div className="rs-table-holder">
            <table className="rs-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Acc. No.</th>
                  <th>Balance</th>

                  {/* <th>Status</th> */}
                  <th>Registered On</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.profileResponseDTOS?.map((d, i) => (
                    <tr key={i}>
                      <td>{data.pageNo * data.pageSize + i + 1}</td>
                      <td>{d.fullName ? d.fullName : "-"}</td>
                      <td>{d.email}</td>
                      <td>{d?.walletAccountDetails?.accountNumber || "-"}</td>
                      <td>
                        {APP_CURRENCY}{" "}
                        {parseInt(d.walletAccountDetails?.balance || 0).toFixed(
                          2
                        )}
                        /-
                      </td>
                      {/* <td>
                        <RSModal
                          selector={
                            <ButtonTextIcon
                              style={{
                                padding: "0 12px 0 4px",
                                height: "28px",
                                width: "90px",
                                borderRadius: "24px",
                                fontSize: "12px",
                                lineHeight: "28px",
                                margin: "0 auto",
                              }}
                              className={`button ${
                                d.statusID === 1
                                  ? "tertiary-view"
                                  : "tertiary-delete"
                              } `}
                              title={d.statusID === 1 ? "Active" : "Inactive"}
                              icon={
                                d.statusID === 1 ? (
                                  <AiOutlineCheckCircle />
                                ) : (
                                  <CiCircleRemove />
                                )
                              }
                            />
                          }
                          content={
                            <Confirmation userId={d.id} status={d.statusID} />
                          }
                        />
                      </td> */}
                      <td>
                        {d?.createdAt ? formatDateTime(d.createdAt) : "-"}
                      </td>
                    </tr>
                  ))}
                  {(!data ||
                    !data.profileResponseDTOS ||
                    data.profileResponseDTOS.length === 0) &&
                    !loading && (
                      <tr>
                        <td
                          colSpan={5}
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          <p> No records found. </p>
                        </td>
                      </tr>
                    )}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <br />
        {data && (
          <Pagination
            totalRecords={data.totalRecords}
            pageNo={data.pageNo}
            pageSize={data.pageSize}
            baseUrl={`/customers?${buildQueryParams({
              filterType,
              search,
              minBalance,
              maxBalance,
              startDate,
              endDate,
            })}&`}
          />
        )}
      </section>
    </>
  );
};

export default CustomerList;
