import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { CiFileOn } from "react-icons/ci";

const FileInput = forwardRef(({ callback, accept, defaultText }, ref) => {
    const [fileName, setFileName] = useState("");
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        reset: () => {
            setFileName("");
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    }));

    return (
        <div className='file-input-holder col-1-1 pos-rel'>
            {fileName ? fileName : defaultText}
            <input
                type='file'
                accept={accept}
                onChange={(e) => {
                    setFileName(e.target.files[0].name);
                    callback(e.target.files[0]);
                }}
                ref={fileInputRef}
            />
            <CiFileOn />
        </div>
    );
});

export default FileInput;
