import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE, SYSTEM_TOKEN } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { FaEye, FaEyeSlash, FaRegEyeSlash } from 'react-icons/fa';

const AddUser = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [clientdata, setClientData] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const userRole = decodeRolesFromToken()[0];
	const token = localStorage.getItem("token");
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


	const validationSchema = Yup.object().shape({
		roleID: Yup.string().required('Role ID is required'),
		// clientID: Yup.string().when('roleID', {
		// 	is: '3', 
		// 	then: Yup.string().required('Client is required'), 
		// 	otherwise: Yup.string().nullable() 
		// }),
		// clientID: Yup.string().required('Client is required').
		// when,
		// gasStationId: Yup.string().required('Site is required'),
		fullName: Yup.string().required('Full name is required'),
		email: Yup.string().email('Invalid email').required('Email is required'),
		password: Yup.string().required('Password is required'),
		confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required!'),
	});

	const initialValues = {
		email: "",
		password: "",
		fullName: "",
		roleID: "",
		gasStationId: "",
		clientID: "",
		confirmPassword:"",
	};

	const handleAddUser = async (values) => {
		console.log("values", values);

		setLoading(true);
		try {
			const request = {
				fullName: values.fullName,
				email: values.email,
				password: values.password,
				roleID: values.roleID,
				gasStationId: values?.gasStationId ? values?.gasStationId : null,
				clientID: values?.clientID ? values?.clientID : null,
				// "phoneNo": "9878987655",
			};

			const response = await fetch(`${API_URL}/api/1.0.0/user/register`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify(request),
			});

			if (response.ok) {
				addToastMessage({
					status: true,
					message: "You have successfully created a user. Thank you!",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a user!",
					status: true,
					archive: false,
				});
				navigate(`/users?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message: errorMessage || "Something went wrong. Please try again!",
				});
			}
		} catch (error) {
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a user.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const fetchClientData = async () => {
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": SYSTEM_TOKEN,
					},
				}
			);
			if (response.ok) {
				const result = await response.json();
				setClientData(result.data);
			}
		} catch (error) {
			console.error("Error fetching client data:", error);
		}
	};

	const fetchGasStations = async () => {
		try {
			const response = await fetch(
				`${API_URL}/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}
			);
			if (response.ok) {
				const result = await response.json();
				setData(result.data);
			}
		} catch (error) {
			console.error("Error fetching sites:", error);
		}
	};

	useEffect(() => {
		fetchClientData();
		fetchGasStations();
		setSegmentTitle("Add Users");
	}, []);

	return (
		<section>
			<PageTitle title='Add Users' />
			<FullPageLoader isActive={loading} />
			<section className='create-station'>
				<h5 className='color-primary'>Users Details</h5>
				<p>Enter the users details below:</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleAddUser}>
						{({ values, errors, touched, setFieldValue }) => (
							<Form>
								<table>
									<tbody>
										{/* <tr>
											<td colSpan='6'>
											<p className="title f-w-700 color-primary">User Type</p>							
												<Field
													name='roleID'
													as='select'
													className={
														(errors.roleID && touched.roleID ? " error" : "")
													}
													onChange={(event) => setFieldValue('roleID', event.target.value)}>
													<option value=''>Select User Type</option>
													<option value='3'>Finance</option>
													<option value='8'>Client Sub Admin</option>
													<option value='4'>Petrol Attendant</option>
													<option value='7'>Cashier</option>
												</Field>
												<ErrorMessage
													name='roleID'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr> */}
										{(userRole === 1 || userRole === 2 || userRole === 6 || userRole === 8) && (
											<tr>
												<td colSpan='6'>
													<p className="title f-w-700 color-primary">User Type</p>
													<Field
														name='roleID'
														as='select'
														className={errors.roleID && touched.roleID ? "error" : ""}
														onChange={(event) => setFieldValue('roleID', event.target.value)}
													>
														<option value=''>Select User Type</option>
														<option value='3'>Financier</option>
														{(userRole === 6 || userRole === 2) && <option value='8'>Client Sub Admin</option>}
														{(userRole === 2) && <option value='6'>Client Admin</option>}
														{(userRole === 8 || userRole === 6 ) && <option value='10'>Site Manager</option>}
														<option value='4'>Petrol Attendant</option>
														<option value='7'>Cashier</option>
													</Field>
													<ErrorMessage
														name='roleID'
														component='p'
														className='error-messages'
													/>
												</td>
											</tr>
										)}
										{values.roleID == 3 || values.roleID == 8 || values.roleID == 6 ? (
											""
										) : (
											<tr>
												<td colSpan='6'>
													<p className="title f-w-700 color-primary">Site Name</p>
													<Field
														name='gasStationId'
														as='select'
														className={
															(errors.gasStationId && touched.gasStationId ? " error" : "")
														}
													>
														<option value=''>Select Site</option>
														{data &&
															data.gasStations?.map((s, i) => (
																<option value={s.id} key={i}>
																	{s.name}
																</option>
															))}
													</Field>
													<ErrorMessage
														name='gasStationId'
														component='p'
														className='error-messages'
													/>
												</td>
											</tr>
										)}
										<tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">Full Name</p>
												<Field
													name='fullName'
													type='text'
													placeholder='Eg: John Smith.'
													className={
														(errors.fullName && touched.fullName ? " error" : "")
													}
												/>
												<ErrorMessage
													name='fullName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">Email</p>
												<Field
													name='email'
													type='email'
													placeholder='Eg: john@xyz.com'
													className={
														(errors.email && touched.email ? " error" : "")
													}
												/>
												<ErrorMessage
													name='email'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
                                            <td colSpan="6">
                                                <p className="title f-w-700 color-primary">Password:</p>
                                                <div className="password-field-wrapper">
                                                    <Field
                                                        name="password"
                                                        type={newPasswordVisible ? 'text' : 'password'}
                                                        placeholder="**********"
                                                        className={
                                                            " " + (errors.password && touched.password ? "error" : "")
                                                        }
                                                    />
                                                    <span
                                                        onClick={toggleNewPasswordVisibility}
                                                        className="eye-icon"
                                                        aria-label={newPasswordVisible ? 'Hide password' : 'Show password'}
                                                    >
                                                        {newPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6">
                                                <p className="title f-w-700 color-primary">Confirm Password:</p>
                                                <div className="password-field-wrapper">
                                                    <Field
                                                        name="confirmPassword"
                                                        type={confirmPasswordVisible ? 'text' : 'password'}
                                                        placeholder="**********"
                                                        className={
                                                            " " + (errors.confirmPassword && touched.confirmPassword ? "error" : "")
                                                        }
                                                    />
                                                    <span
                                                        onClick={toggleConfirmPasswordVisibility}
                                                        className="eye-icon"
                                                        aria-label={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                                                    >
                                                        {confirmPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
                                                    </span>
                                                </div>
                                                <ErrorMessage
                                                    name='confirmPassword'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>
										{/* <tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">Password</p>
												<Field
													name='password'
													type='password'
													placeholder='Eg: john****'
													autocomplete="off"
													className={
														(errors.password && touched.password ? " error" : "")
													}
												/>
												<ErrorMessage
													name='password'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr> */}
										<tr>
											<td colSpan="6" className="btns t-a-r">
												<NavLink to={`/users?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon type="button" title="Cancel" />
												</NavLink>
												<ButtonTextOnly type="submit" title="Create Now" />
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};

export default AddUser;



