import { CiCircleRemove, CiCirclePlus, CiBarcode } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken } from "../../utilities/helpers";
import { useEffect, useState, useRef } from "react";
import { API_URL, BASE_URL } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";
import RSModal from "../../components/ui/RSModal";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const GasStation = () => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingClient, setLoadingClient] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { setSegmentTitle } = useUI();
	const [clientdata, setClientData] = useState(null);
	const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
	const pageSize = new URLSearchParams(location.search).get("pageSize") || 10;
	const clientId = new URLSearchParams(location.search).get("clientId") || "";

	const callClientListApi = async () => {
		setLoadingClient(true);
		try {
			const resp = await fetch(
				`${API_URL}/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				if(response.result.responseCode === 200){
					setClientData(response.data);
				}else{
					setClientData(null);
				}
			}else{
				setClientData(null);
			}
		} catch (e) {
			console.error("Error", e);
			setClientData(null);
		} finally {
			setLoadingClient(false);
		}
	};

	const callListApi = async () => {
		setLoading(true);

		let endpoint = `/api/1.0.0/gasstation?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=desc`;

		if (clientId) {
			endpoint += `&clientID=${clientId}`;
		}
		try {
			const resp = await fetch(API_URL + endpoint, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});

			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setData(response.data);
				} else {
					setData(null);
				}
			} else {
				setData(null);
			}
		} catch (e) {
			console.error("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		callListApi();
		callClientListApi();
		setSegmentTitle("Sites");
	}, [location.search]);

	const handleClientChange = (event) => {
		const clientId = event.target.value;
		navigate(`/sites?pageNo=0&pageSize=${pageSize}&clientId=${clientId}`);
	};

	const userRole = decodeRolesFromToken()[0];

	return (
		<>
			<PageTitle title="Sites" />
			<FullPageLoader isActive={loading || loadingClient} />
			<section>
				<div className="flex center-left responsive-container">
					<ResultsPerPage targetURL={`/sites?pageNo=0&pageSize=`} />
					{(userRole === 1 || userRole === 2 ) && (
						<select
							onChange={handleClientChange}
							style={{ width: "350px" }}
							value={clientId}
						>
							<option value="">All Client</option>
							{clientdata &&
								clientdata.clients &&
								clientdata.clients.map((c, i) => (
									<option value={c.id} key={i}>
										{c.name}
									</option>
								))}
						</select>
					)}
					{(userRole === 6 || userRole === 8) && (
						<div style={{ marginLeft: "auto" }}>
							<NavLink to="/sites/add">
								<ButtonTextIcon title="Add New" icon={<CiCirclePlus />} />
							</NavLink>
						</div>
					)}
				</div>

				<div className="results">
					<div className="rs-table-holder">
						<table className="rs-table">
							<thead>
								<tr>
									<th>#</th>
									<th>Site Name</th>
									<th>Client Name</th>
									<th>Address</th>
									<th>QR Code</th>
								</tr>
							</thead>
							
								<tbody>
									{ data && data?.gasStations?.map((d, i) => (
										<tr key={i}>
											<td>{data.pageNo * data.pageSize + i + 1}</td>
											<td>{d.name}</td>
											<td>{d.clientName}</td>
											<td>{d.address}</td>
											<td>
												<div className="flex center-center" style={{ gap: "12px" }}>
													<RSModal
														selector={<CiBarcode style={{ height: "16px", width: "16px" }} />}
														content={<QRCodeComponent data={{ qrtext: d.qrText, name: d.name }} />}
													/>
												</div>
											</td>
										</tr>
									))}
									{(!data || !data.gasStations || data.gasStations.length === 0) && !loading && (
										<tr>
											<td colSpan={5} style={{ textAlign: "center", padding: "10px" }} >
												<p> No records found. </p>
											</td>
										</tr>
									)}
								</tbody>
							
						</table>
					</div>
				</div>
				<br />
				{data && (
					<Pagination
						totalRecords={data.totalRecords}
						pageNo={data.pageNo}
						pageSize={data.pageSize}
						baseUrl={`/sites?`}
					/>
				)}
			</section>
		</>
	);
};

const QRCodeComponent = ({ data, setShow }) => {
	const qrcodeTemplateRef = useRef(null);
	const [qr, setQr] = useState("");

	useEffect(() => {
		handleGenerateQRCode();
	}, [data.qrtext]);

	const handleGeneratePdf = () => {
		const input = qrcodeTemplateRef.current;
		if (!input) {
			console.error('QR code template not found');
			return;
		}
		const margin = 40;
		const logoBase64 = `${BASE_URL}/images/logo/1x.png`;

		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL("image/png");

				if (!imgData || !imgData.startsWith('data:image/png;base64,')) {
					console.error('Invalid image data URL');
					return;
				}
				const pdf = new jsPDF("p", "mm", "a4");
				const imgProps = pdf.getImageProperties(imgData);
				const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
				const positionX = margin;
				const positionY = margin;
				// pdf.addImage(logoBase64, "PNG", 10, 10, 40, 20); // Adjust size as needed

				pdf.addImage(imgData, "PNG", positionX, positionY + 30, pdfWidth, pdfHeight);
				const filename = `${data.name.replace(/\s+/g, '-')}-qrcode.pdf`;
				pdf.save(filename);
			})
			.catch((error) => {
				console.error("Error generating PDF:", error);
			});
	};

	const handleGenerateQRCode = () => {
		const qrcode = data.qrtext;
		QRCode.toDataURL(
			qrcode,
			{
				width: 800,
				margin: 2,
				color: {
					dark: "#335383FF",
					light: "#EEEEEEFF",
				},
			},
			(err, qrcode) => {
				if (err) {
					console.error(err);
					return;
				}
				setQr(qrcode);
			}
		);
	};
	const imageStyle = {
		display: 'block',
		width: '280px',
		height: '280px',
		margin: '2rem auto',
	};

	return (
		<div className="pos-rel" style={{ width: "480px", padding: "40px" }}>
			<CiCircleRemove
				className="cursor-pointer transition hover-color-secondary"
				onClick={() => setShow(false)}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					fontSize: "32px",
				}}
			/>
			<h4 className="color-primary">QR Code</h4>
			{/* <br /> */}
			<div ref={qrcodeTemplateRef} style={{margin:"10px"}}>
				<section
					style={{
						border: "solid 1px",
						borderRadius: "10px",
						padding: "10px",
					}}
				>
					<div className="flex center-center">
						<div className="t-a-c">
							{/* <div className="flex center-center">
								<h5>Scan this QR Code</h5><hr />
							</div> */}
							{qr ? (
								<div>
									<img src={qr} alt="QR Code" style={imageStyle} />
									<div className="flex center-center">
										<h6 className="font-size-14">{data.name}</h6>
									</div><br />
								</div>
							) : (
								<p>Loading QR Code...</p>
							)}
						</div>
					</div>
				</section>
			</div>
			<div className="flex center-center">
				<button onClick={handleGeneratePdf}>
					Download
				</button>
			</div>
		</div>
	);
};

export default GasStation;
