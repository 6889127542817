import {
	CiMoneyBill,
	CiReceipt,
	CiShop,
	CiUser,
} from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { useCallback, useEffect, useState } from "react";
import { API_URL, APP_CURRENCY } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useFetch } from "../../hooks/useFetch";
import { decodeRolesFromToken, formatAmountValue } from "../../utilities/helpers";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";

const buildQueryParams = (params) => Object.entries(params)
	.filter(([_, value]) => value !== "")
	.map(([key, value]) => `${key}=${value}`)
	.join("&");

const AdminDashboard = () => {
	const [amount, setAmount] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingClient, setLoadingClient] = useState(false);
	const [loadingSite, setLoadingSite] = useState(false);
	const [listdata, setData] = useState(null);
	const [settlementData, setSettlementData] = useState(null);
	const [clientId, setClientId] = useState("");
	const [gasStationId, setGasStationId] = useState("");
	const [clientData, setClientData] = useState(null);
	const [gasStationsData, setGasStationsData] = useState(null);
	const [userClientId, setUserClientId] = useState("");
	const { setSegmentTitle } = useUI();
	const [dashboardData, setDashboardData] = useState(null);
	const userRole = decodeRolesFromToken()[0];


	const fetchData = useCallback(async (endpoint, setState, setLoading) => {
		setLoading(true);
		try {
			const response = await fetch(`${API_URL}${endpoint}`, {
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (response.ok) {
				const data = await response.json();
				if (data.result.responseCode === 200) {
					setState(data.data || null);
				} else {
					setState(null);
				}
			} else {
				setState(null);
			}
		} catch (e) {
			console.error("Error fetching data", e);
			setState(null);
		} finally {
			setLoading(false);
		}
	}, []);


	useEffect(() => {
		fetchData("/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc", setClientData, setLoadingClient);
	}, [fetchData]);

	useEffect(() => {
		if (clientId || userClientId) {
			fetchData(`/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc&clientID=${clientId || userClientId}`, setGasStationsData, setLoadingSite);
		}
	}, [clientId, userClientId, fetchData]);

	const { data } = useFetch(API_URL + "/api/1.0.0/user/profile", {
		method: "GET",
	});

	useEffect(() => {
		if (data) {
			setUserClientId(data.data.clientId || "");
		}
	}, [data]);

	const accountNumber = data?.data?.walletAccountDetails?.accountNumber || "";

	const callApi = async (accountNumber) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/ocpl/wallet/${accountNumber}/balance`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	const callAccountApi = async (accountNumber) => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/ocpl/wallet/${accountNumber}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setAmount(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if ([1, 2, 9, 6, 8, 3].includes(userRole)) {
			console.log({ clientId: [6, 8, 3].includes(userRole) ? "" : clientId, gasStationId })
			fetchData(`/api/1.0.0/settlement/settlement-dashboard?${buildQueryParams({ clientId: [6, 8, 3].includes(userRole) ? "" : clientId, gasStationId })}`, setSettlementData, setLoading);
		}
	}, [clientId, gasStationId])

	const callDashBoardApi = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/user/admin-dashboard`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.ok) {
				const response = await resp.json();
				setDashboardData(response.data);
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (accountNumber) {
			callApi(accountNumber);
			callAccountApi(accountNumber);
		}
		callDashBoardApi();
		setSegmentTitle("Dashboard");
	}, [accountNumber]);

	return (
		<>
			<PageTitle title='Dashboard' />
			<FullPageLoader isActive={loading || loadingClient || loadingSite} />
			<section className='dashboard'>

				{![6, 8, 10, 7, 4, 3].includes(userRole) && (
					<div className='quick-links'>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiMoneyBill
									className='color-secondary'
									style={{ height: "40px", width: "auto" }}
								/>
								<div>
									<p className='color-faded font-size-12 f-w-400 l-h-1'>
										{amount ? amount.description : ""} Account
										No.
									</p>
									<h6 className='color-primary f-w-700 l-h-1'>
										{amount ? amount.accountNumber : "XXXXXXXXXXX"}
									</h6>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiMoneyBill
									className='color-secondary'
									style={{ height: "40px", width: "auto" }}
								/>
								<div>
									<p className='color-faded font-size-12 f-w-400 l-h-1'>
										Balance
									</p>
									<h6 className='color-primary f-w-700 l-h-1'>
										{APP_CURRENCY}{" "}
										{listdata
											? `${formatAmountValue(listdata.balance)}/- `
											: "XXXXXXXXX.XX/-"}
									</h6>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiShop
									className='color-secondary'
									style={{ height: "40px", width: "auto" }}
								/>
								<div>
									<p className='color-faded font-size-12 f-w-400 l-h-1'>
										Sites
									</p>
									<h6 className='color-primary f-w-700 l-h-1'>
									{dashboardData ? dashboardData.siteCount : "0"}
									</h6>
								</div>
							</div>
						</div>
						<div className='one flex center-left'>
							<div
								className='flex center-left'
								style={{ gap: "10px" }}>
								<CiUser
									className='color-secondary'
									style={{ height: "40px", width: "auto" }}
								/>
								<div>
									<p className='color-faded font-size-12 f-w-400 l-h-1'>
										Users
									</p>
									<h6 className='color-primary f-w-700 l-h-1'>
									{dashboardData ? dashboardData.userCount : "0"}
									</h6>
								</div>
							</div>
						</div>
					</div>)}

				{[1, 2, 9, 6, 8, 3].includes(userRole) && (
					<div className="reconciliation-section">
						<div>
							<h6 className="color-primary">Transactions Details </h6>
							<div className="flex center-left responsive-container">
								<select
									onChange={(event) => {
										setGasStationId("");
										setGasStationsData(null);
										setClientId(event.target.value);
									}}
									className="responsive-select"
									value={clientId}
									disabled={[6, 8, 3].includes(userRole)}
									style={{
										display: [6, 8, 3].includes(userRole) && "none"
									}}
								>
									{![6, 8, 3].includes(userRole) && <option value="">All Client</option>}
									{/* <option value="">All Client</option> */}
									{clientData?.clients?.map((client, i) => {
										if ([6, 8, 3].includes(userRole)) {
											if (client.id === userClientId) {
												return <option key={i} value={client.id}>{client.name}</option>
											}
										} else {
											return <option key={i} value={client.id}>{client.name}</option>
										}
									}
									)}
								</select>

								<select
									onChange={(event) => { setGasStationId(event.target.value); }}
									className="responsive-select"
									value={gasStationId}
								>
									<option value="">All Site</option>
									{gasStationsData?.gasStations?.map((station, i) => (
										<option key={i} value={station.id}>{station.name}</option>
									))}
								</select>
							</div>
							<div className="reconciliation-container">
								<div class="ui cards">
									<div class="card">
										<div class="content">
											<div className='header color-primary font-size-12'>
												<div>
													All Transactions
												</div>
												<div className="color-secondary mini ui image">
													<CiMoneyBill
														style={{ height: "30px", width: "auto" }}
													/>
												</div>
											</div>
											<div class="meta">
												<p>
													<span className='color-faded '>Transaction:</span> {settlementData?.totalTransaction || 0}
													<br />
													<span className='color-faded '>Current Balance:</span> {APP_CURRENCY}  {settlementData?.totalUnsettledAmount ? formatAmountValue(settlementData?.totalUnsettledAmount) : 0}/-
												</p>
											</div>
										</div>
									</div>
									<div class="card">
										<div class="content">
											<div className='header color-primary font-size-12'>
												<div>
													All Settled
												</div>
												<div className="color-secondary mini ui image">
													<CiMoneyBill
														style={{ height: "30px", width: "auto" }}
													/>
												</div>
											</div>
											<div class="meta">
												<p>
													<span className='color-faded '>Transaction:</span> {settlementData?.settledTxnCount || 0}
													<br />
													<span className='color-faded '>Amount:</span> {APP_CURRENCY} {settlementData?.totalSettledAmount ? formatAmountValue(settlementData?.totalSettledAmount) : 0}/-

												</p>
											</div>
											{/* <div class="description">
												Elliot requested permission to view your contact details
											</div> */}
										</div>
									</div>
									<div class="card">
										<div class="content">
											<div className='header color-primary font-size-12'>
												<div>
													All Unsettled
												</div>
												<div className="color-secondary mini ui image">
													<CiMoneyBill
														style={{ height: "30px", width: "auto" }}

													/>
												</div>

											</div>
											<div class="meta">
												<p>
													<span className='color-faded '>Transaction:</span>  {settlementData?.unsettledTxnCount || 0}
													<br />
													<span className='color-faded '>Amount:</span> {APP_CURRENCY}  {settlementData?.totalUnsettledAmount ? formatAmountValue(settlementData?.totalUnsettledAmount) : 0}/-

												</p>
											</div>
										</div>
									</div>
								</div>
								{/* <div className='one flex center-left'>
									<div
										className='flex center-left'
										style={{ gap: "10px" }}>
										<CiMoneyBill
											className='color-secondary'
											style={{ height: "40px", width: "auto" }}
										/>
										<div>
											<p className='color-faded font-size-12 f-w-400 l-h-1'>
												All Transactions
											</p>
											<h6 className='color-primary f-w-700 l-h-1'>
												<span className='color-faded '>Transaction:</span> {settlementData?.totalTransaction || 0}
												&nbsp;&nbsp;&nbsp;
												<span className='color-faded '>Current Balance:</span> {APP_CURRENCY}  {settlementData?.unsettledTxnCount ? formatAmountValue(settlementData?.unsettledTxnCount) : 0}/-

											</h6>
										</div>
									</div>
								</div>
								<div className='one flex center-left'>
									<div
										className='flex center-left'
										style={{ gap: "10px" }}>
										<CiMoneyBill
											className='color-secondary'
											style={{ height: "40px", width: "auto" }}
										/>
										<div>
											<p className='color-faded font-size-12 f-w-400 l-h-1'>
												All Settled
											</p>
											<h6 className='color-primary f-w-700 l-h-1'>
												<span className='color-faded '>Transaction:</span> {settlementData?.settledTxnCount || 0}
												&nbsp;&nbsp;&nbsp;
												<span className='color-faded '>Amount:</span> {APP_CURRENCY} {settlementData?.totalSettledAmount ? formatAmountValue(settlementData?.totalSettledAmount) : 0}/-
											</h6>
										</div>
									</div>
								</div>
								<div className='one flex center-left'>
									<div
										className='flex center-left'
										style={{ gap: "10px" }}>
										<CiMoneyBill
											className='color-secondary'
											style={{ height: "40px", width: "auto" }}
										/>
										<div>
											<p className='color-faded font-size-12 f-w-400 l-h-1'>
												All Unsettled
											</p>
											<h6 className='color-primary f-w-700 l-h-1'>
												<span className='color-faded '>Transaction:</span>  {settlementData?.unsettledTxnCount || 0}
												&nbsp;&nbsp;&nbsp;
												<span className='color-faded '>Amount:</span> {APP_CURRENCY}  {settlementData?.unsettledTxnCount ? formatAmountValue(settlementData?.unsettledTxnCount) : 0}/-
											</h6>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				)
				}

				{[10].includes(userRole) && (
					<div className="reconciliation-section">
						<h6 className="color-primary">Config Details </h6>

						<div className="reconciliation-container">
							<div className="ui cards">
								<div class="card">
									<div class="content">
										<div className='header color-primary font-size-12'>
											<div>
												Total Transactions
											</div>
											<div className="color-secondary mini ui image">
												<CiMoneyBill
													style={{ height: "30px", width: "auto" }}

												/>
											</div>

										</div>
										<div class="meta">
											<p>
												<span className='color-faded '>Number of Transaction:</span> {data?.data?.numberOfTransactions || 0}													<br />

											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="content">
										<div className='header color-primary font-size-12'>
											<div>
												Report Status
											</div>
											<div className="color-secondary mini ui image">
												<CiReceipt
													style={{ height: "30px", width: "auto" }}

												/>
											</div>

										</div>
										<div class="meta">
											<p>
												<span className='color-faded '>Download Report Status:</span> {data?.data?.downloadReport ? "Enable" : "Disable"}

											</p>
										</div>
									</div>
								</div>
							</div>

							{/* <div className='one flex center-left'>
									<div
										className='flex center-left'
										style={{ gap: "10px" }}>
										<CiMoneyBill
											className='color-secondary'
											style={{ height: "40px", width: "auto" }}
										/>
										<div>
											<p className='color-faded font-size-12 f-w-400 l-h-1'>
												Total Transactions
											</p>
											<h6 className='color-primary f-w-700 l-h-1'>
												<span className='color-faded '>Number of Transaction:</span> {data?.data?.numberOfTransactions || 0}
												&nbsp;&nbsp;&nbsp;

											</h6>
										</div>
									</div>
								</div>
								<div className='one flex center-left'>
									<div
										className='flex center-left'
										style={{ gap: "10px" }}>
										<CiMoneyBill
											className='color-secondary'
											style={{ height: "40px", width: "auto" }}
										/>
										<div>
											<p className='color-faded font-size-12 f-w-400 l-h-1'>
												Report Status
											</p>
											<h6 className='color-primary f-w-700 l-h-1'>
												<span className='color-faded '>Download Report Status:</span> {data?.data?.downloadReport ? "Enable" : "Disable"}
												&nbsp;&nbsp;&nbsp;

											</h6>
										</div>
									</div>
								</div> */}

						</div>
					</div>
				)
				}



			</section>
		</>
	);
};

export default AdminDashboard;
