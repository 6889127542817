import { jwtDecode } from "jwt-decode";

export const formatYYYYMMDD = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr = str.split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const decodeRolesFromToken = () => {
	const ls = localStorage.getItem("token");
	if (ls) {
		const decodedToken = jwtDecode(ls);
		const roles = decodedToken.authorities;
		return roles;
	} else {
		return [];
	}
};

export const formatServerDate = (str) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const arr0 = str.split("T");
	const arr = arr0[0].split("-");
	return arr[2] + " " + months[parseInt(arr[1] - 1)] + " " + arr[0];
};

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export function formatDateTime(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const formattedDate = `${formatServerDate(
		dateString
	)}, ${hours}:${minutes}:${seconds}`;

	return formattedDate;
}


export const formatAmountValue = (value) => {
	if (isNaN(value)) return value;
	return parseFloat(value).toLocaleString('en-US', {
	  minimumFractionDigits: 2,
	  maximumFractionDigits: 2,
	});
  };


