import { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FileInput from "../../components/ui/FileInput";
import { API_URL, APP_CURRENCY } from "../../utilities/constants";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { useFetch } from "../../hooks/useFetch";

const SiteConfig = () => {
    const [initialValues, setInitialValues] = useState({ numberOfTransactions: 0, downloadReport: false, });
    const [loading, setLoading] = useState(false);
    const { 
		setNewNotification,
		setShowNotification,
		addNotification,
		addToastMessage,
        setSegmentTitle, } = useUI();
    const token = localStorage.getItem("token");
    const { data : profileData, error : profileError, loading : profileLoading } = useFetch(API_URL + "/api/1.0.0/user/profile", {method: "GET", });
    
    useEffect(() => { setSegmentTitle("Site Config"); }, [setSegmentTitle]);

    useEffect(()=>{
        if(!profileError){
            if(profileData && profileData?.data){
                 setInitialValues({ 
                    numberOfTransactions: profileData?.data?.numberOfTransactions || 0, 
                    downloadReport: profileData?.data?.downloadReport || false 
                 }); 
            }
        }
    },[profileData]);

    const validationSchema = Yup.object().shape({
        numberOfTransactions: Yup.number().min(1, "Minimum 1 transaction").required("Required"),
        downloadReport: Yup.boolean().required("Required"),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);
		try {
			const request = {
				numberOfTransactions: values.numberOfTransactions,
				downloadReport: values.downloadReport,
			};

			const resp = await fetch(API_URL + "/api/1.0.0/gasstation/config", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify(request),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message: "You have successfully save configuration, Thank You!",
				});
				setNewNotification(true);
				setShowNotification(false);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a site!",
					status: true,
					archive: false,
				});
			}
			else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message: errorMessage || "Something went wrong while creating a site. Please try again!",
				});
			}
		} catch (e) {
            addToastMessage({
                status: false,
                message: "Something went wrong while creating a site.",
            }); 
		} finally {
			setLoading(false);
		}
      };

    return (<section>
        <PageTitle title='Site Config' />
        <FullPageLoader isActive={loading} />
        <section className='create-station' style={{ maxWidth: "600px"}}>
            <h5 className='color-primary'>Site Configuration Details</h5>
            <p>Configure the settings for transaction records and report
                downloading. These settings affect the visibility of transaction
                records for Petrol Attendants and the ability of Cashiers to
                download transaction reports.</p>
            <br />
            <section className='table-wrapper'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan='6'>
                                            <p className="title f-w-700 color-primary">Number of Transactions</p>
                                            <Field
                                                name='numberOfTransactions'
                                                type='number'
                                                placeholder='Eg: [ 1 ]*'
                                                className={
                                                    (errors.numberOfTransactions && touched.numberOfTransactions ? " error" : "")
                                                }
                                            />
                                            <ErrorMessage
                                                name='numberOfTransactions'
                                                component='p'
                                                className='error-messages'
                                            />
                                            <p className="l-h-1 color-secondary" style={{fontSize: "12px", paddingTop:"10px"}} >
                                                This setting determines the number of the latest transaction records
                                                visible to the Petrol Attendants.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='6'>
                                        <p className="title f-w-700 color-primary">Download Report</p>
                                            <label
                                                htmlFor='checkId'
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "12px",
                                                    fontSize: "10px",
                                                    cursor: "pointer",
                                                }}>
                                                <Field type='checkbox' id='checkId' name='downloadReport' />
                                                <p  className="title f-w-600 color-primary" style={{paddingTop: "5px"}} >{values.downloadReport ? "Enabled " :"Disabled"}</p>
                                            </label>
                                            <ErrorMessage
                                                name='downloadReport'
                                                component='p'
                                                className='error-messages'
                                            />
                                             <p className="l-h-1 color-secondary" style={{fontSize: "12px", paddingTop:"10px"}} >
                                                This setting allows Cashiers to download a report of transactions.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
											<td colSpan="6" className="btns t-a-r">
												<ButtonTextOnly type="submit" title="Save Config" />
											</td>
										</tr>
                                </tbody>
                            </table>
                        </Form>
                    )}
            </Formik>
            </section>
        </section>
    </section>)
}

export default SiteConfig;