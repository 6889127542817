import { CiSearch, CiRedo, CiCircleCheck, CiFileOn, CiMoneyBill } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { formatAmountValue, formatDateTime } from "../../utilities/helpers";
import { useEffect, useState, useCallback } from "react";
import { API_URL, APP_CURRENCY, DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import DatePicker from 'react-datepicker';
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";
import RSModal from "../../components/ui/RSModal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import LoaderIcon from "../../components/common/LoaderIcon";
import 'react-datepicker/dist/react-datepicker.css';

const buildQueryParams = (params) => Object.entries(params)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const calculateTransactionStats = (txnIds) => {
    if (txnIds.length === 0) {
        return { amount: 0, count: 0 };
    }
    const totalAmount = txnIds.reduce((total, txn) => total + (txn.transactionAmount || 0), 0);
    return { amount: totalAmount, count: txnIds.length };
};

function getDefaultDateTimes() {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 6, 0, 0);
    let endDate;
    if (now.getDay() === 1) { 
        const daysToSubtract = 2; 
        endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysToSubtract, 6, 0, 0);
    } else {
        endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 6, 0, 0);
    }
    return { startDate:formatDateToCustomString(startDate), endDate:formatDateToCustomString(endDate) };
}

const Settlement = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingClient, setLoadingClient] = useState(false);
    const [loadingSite, setLoadingSite] = useState(false);
    const [clientIdValue, setClientIdValue] = useState("");
    const [clientData, setClientData] = useState(null);
    const [gasStationsData, setGasStationsData] = useState(null);
    const [allChecked, setAllChecked] = useState(true);
    const [excludedTxnIds, setExcludedTxnIds] = useState([]);
    const [includedTxnIds , setIncludedTxnIds] = useState([]);
    const { setSegmentTitle } = useUI();
    const searchParams = new URLSearchParams(location.search);
    const {startDate: defaultStartDate,endDate: defaultEndDate } = getDefaultDateTimes();
    const pageNo = searchParams.get("pageNo") || 0;
    const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
    const fromDateTime = searchParams.get("fromDateTime") || defaultEndDate;
    const toDateTime = searchParams.get("toDateTime") || defaultStartDate;
    const gasStationId = searchParams.get("gasStationId") || "";
    const clientId = searchParams.get("clientId") || "";
    
    const fetchData = useCallback(async (endpoint, setState, setLoading) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}${endpoint}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.result.responseCode === 200) {
                    setState(data.data || null);
                } else {
                    setState(null);
                }
            } else {
                setState(null);
            }
        } catch (e) {
            console.error("Error fetching data", e);
            setState(null);
        } finally {
            setLoading(false);
        }
    }, []);

    const callApi = useCallback(() => {
        if (fromDateTime && toDateTime && clientId && gasStationId) {
            const queryParams = buildQueryParams({
                sortOrder: "desc",
                endDateTime: toDateTime.replace(' ', 'T') + ':00:00',
                startDateTime: fromDateTime.replace(' ', 'T') + ':00:00',
                //clientId ,
                gasStationId,
                pageNo,
                pageSize,
            });
            setClientIdValue(clientId);
            fetchData(`/api/1.0.0/settlement?${queryParams}`, (data) => {
                setData(data);
            }, setLoading);
        } else {
            setData(null)
        }
    }, [pageNo, pageSize, fromDateTime, toDateTime, clientId, gasStationId]);

    useEffect(() => {
        fetchData("/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc", setClientData, setLoadingClient);
    }, [fetchData]);

    useEffect(() => {
        if (clientIdValue) {
            fetchData(`/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc&clientID=${clientIdValue}`, setGasStationsData, setLoadingSite);
        }
    }, [clientIdValue, fetchData]);

    useEffect(() => {
        callApi();
        setSegmentTitle("Transaction Settlement");
    }, [location.search])


    const toggleTxnId = (d) => {
        if(allChecked){
            setExcludedTxnIds((prevIds) => {
                const itemExists = prevIds.find((obj) => obj.id === d.id);
                if (itemExists) {
                    return prevIds.filter((obj) => obj.id !== d.id);
                } else {
                    return [...prevIds, { ...d }];
                }
            });
        }else{
            setIncludedTxnIds((prevIds) => {
                const itemExists = prevIds.find((obj) => obj.id === d.id);
                if (itemExists) {
                    return prevIds.filter((obj) => obj.id !== d.id);
                } else {
                    return [...prevIds, { ...d }];
                }
            });
        }
    };

    const initialValues = { fromDateTime: fromDateTime, toDateTime: toDateTime, clientId: clientId, gasStationId: gasStationId };

    const isValidDateFormat = (value) => {
        const regex = /^\d{4}-\d{2}-\d{2} \d{2}$/;
        if (!regex.test(value)) return false;

        const date = new Date(value.replace(' ', 'T') + ':00');
        return date.toString() !== 'Invalid Date';
    };

    const FormValidation = Yup.object().shape({
        clientId: Yup.string().required("Client is required"),
        gasStationId: Yup.string().required("Site is required"),


        fromDateTime: Yup.string()
            .required("Start date time is required")
            .test(
                "valid-date-format",
                "Invalid start date time format. Use yyyy-MM-dd HH",
                (value) => isValidDateFormat(value) // Assuming `isValidDateFormat` checks if the value follows the required format
            )
            .test(
                "before-to-date",
                "Start date time must be before End date time",
                function (value) {
                    const { toDateTime } = this.parent;

                    // Log the value for debugging
                    console.log("toDateTime value:", toDateTime);
                    console.log("toDateTime type:", typeof toDateTime);

                    if (!value || !toDateTime) return true;

                    // Ensure toDateTime is a string
                    const toDateString = typeof toDateTime === 'string' ? toDateTime : String(toDateTime);

                    const fromDate = new Date(value.replace(' ', 'T') + ':00');
                    const toDate = new Date(toDateString.replace(' ', 'T') + ':00');

                    return fromDate < toDate;
                }
            ),

        toDateTime: Yup.string()
            .required("End date time is required")
            .test(
                "valid-date-format",
                "Invalid end date time format. Use yyyy-MM-dd HH",
                (value) => isValidDateFormat(value)
            )
            .test(
                "after-from-date",
                "End date time must be after Start date time",
                function (value) {
                    const { fromDateTime } = this.parent;

                    // Log the value for debugging
                    console.log("fromDateTime value:", fromDateTime);
                    console.log("fromDateTime type:", typeof fromDateTime);

                    if (!value || !fromDateTime) return true;

                    // Ensure fromDateTime is a string
                    const fromDateString = typeof fromDateTime === 'string' ? fromDateTime : String(fromDateTime);

                    const fromDate = new Date(fromDateString.replace(' ', 'T') + ':00');
                    const toDate = new Date(value.replace(' ', 'T') + ':00');

                    return fromDate < toDate;
                }
            )
            .test(
                "before-2-hours",
                "End date time must be at least 2 hours before the current time",
                function (value) {
                    const now = new Date();
                    const endDateTime = new Date(value.replace(' ', 'T') + ':00');
                    return endDateTime <= new Date(now.getTime() - 2 * 60 * 60 * 1000); // Ensure it's at least 2 hours before now
                }
            )


        // fromDateTime: Yup.string()
        //     .required("Start date time is required")
        //     .test(
        //         "valid-date-format",
        //         "Invalid start date time format. Use yyyy-MM-dd HH",
        //         value => isValidDateFormat(value)
        //     )
           
        //     .test(
        //         "before-to-date",
        //         "Start date time must be before End date time",
        //         function (value) {
        //             const { toDateTime } = this.parent;

        //             if (!value || !toDateTime) return true;

        //             // Ensure toDateTime is a string, or convert it to one
        //             const toDateString = typeof toDateTime === 'string' ? toDateTime : String(toDateTime);

        //             // Parse both dates safely
        //             const fromDate = new Date(value.replace(' ', 'T') + ':00');
        //             const toDate = new Date(toDateString.replace(' ', 'T') + ':00');

        //             return fromDate < toDate;
        //         }
        //     )

        //     .test(
        //         "before-to-date",
        //         "Start date time must be before End date time",
        //         function (value) {
        //             const { toDateTime } = this.parent;
        //             if (!value || !toDateTime) return true;
        //             const fromDate = new Date(value.replace(' ', 'T') + ':00');
        //             const toDate = new Date(toDateTime.replace(' ', 'T') + ':00');
        //             return fromDate < toDate;
        //         }
        //     ),
        // toDateTime: Yup.string()
        //     .required("End date time is required")
        //     .test(
        //         "valid-date-format",
        //         "Invalid end date time format. Use yyyy-MM-dd HH",
        //         value => isValidDateFormat(value)
        //     )
        //     .test(
        //         "before-2-hours",
        //         "End date time must be at least 2 hours before the current time",
        //         function (value) {
        //             const now = new Date();
        //             const endDateTime = new Date(value.replace(' ', 'T') + ':00');
        //             return endDateTime <= new Date(now.getTime() - 2 * 60 * 60 * 1000);
        //         }
        //     ),
    });

    const resetFilter = () => { navigate(`/settlement?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`); }

    const handleClientChange = async (event) => {
        const clientIdValue = event.target.value;
        setClientIdValue(clientIdValue);
    };

    const { amount: excludedTxnAmount, count: excludedTxnNo } = calculateTransactionStats(excludedTxnIds);
    const { amount: includedTxnAmount, count: includedTxnNo } = calculateTransactionStats(includedTxnIds);
    
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setAllChecked(isChecked); 
        setIncludedTxnIds([]);
        setExcludedTxnIds([]);  
    };

      
    return (
        <section >
            <PageTitle title="Transaction Settlement" />
            <FullPageLoader isActive={loading || loadingClient || loadingSite} />
            <section>
                <div className="flex center-left responsive-container">
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={FormValidation}
                            enableReinitialize
                            onSubmit={async (values, { resetForm }) => {
                                console.log(values);
                                const queryParams = buildQueryParams({
                                    clientId: values.clientId,
                                    fromDateTime: values.fromDateTime,
                                    gasStationId: values.gasStationId,
                                    toDateTime: values.toDateTime,
                                    pageNo: 0,
                                    pageSize
                                });
                                setExcludedTxnIds([])
                                navigate(`/settlement?${queryParams}`);
                            }}>
                            {({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                resetForm,
                            }) => (
                                <Form>
                                    <p className="font-size-14 f-w-700" style={{ color: "red" }}>Note: The end date and time must be at least 2 hours earlier than the current time. This ensures that the end time is not set too close to the present moment.</p>
                                    <div
                                        className='flex center-left responsive-container'
                                        style={{ gap: "12px" }}>

                                        <ResultsPerPage targetURL={`/settlement?${buildQueryParams({ fromDateTime, toDateTime, clientId, gasStationId })}&pageNo=0&pageSize=`} />
                                        <div>
                                            <Field
                                                as='select'
                                                name='clientId'
                                                className={
                                                    "responsive-select " +
                                                    (errors.clientId && touched.clientId ? " error" : "")
                                                }
                                                onClick={handleClientChange}
                                            >
                                                <option value="">Select Client</option>
                                                {clientData && clientData?.clients && clientData.clients?.map((c, i) => (
                                                    <option value={c.id} key={i} > {c.name} </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <div>
                                            <Field
                                                as='select'
                                                name='gasStationId'
                                                className={
                                                    "responsive-select " +
                                                    (errors.gasStationId &&
                                                        touched.gasStationId
                                                        ? " error"
                                                        : "")
                                                }>
                                                <option value=''>Select Site</option>
                                                {gasStationsData && gasStationsData?.gasStations && gasStationsData.gasStations?.map((c, i) => (
                                                    <option value={c.id} key={i} > {c.name} </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <div>
                                            <Field
                                                name="fromDateTime"
                                                component={DateTimePickerField}
                                                value={values.fromDateTime}
                                                placeholder="Select From Date Time"
                                                className={" " + (errors.fromDateTime && touched.fromDateTime ? " error" : "")}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="toDateTime"
                                                component={DateTimePickerField}
                                                value={values.toDateTime}
                                                placeholder="Select To Date Time"
                                                className={" " + (errors.toDateTime && touched.toDateTime ? " error" : "")}
                                            />
                                        </div>
                                        <div>
                                            <ButtonTextIcon type='submit' icon={<CiSearch />} />
                                        </div>
                                        <div className="responsive-button">
                                            <ButtonTextIcon
                                                type='button'
                                                onClick={resetFilter}
                                                icon={<CiRedo />}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>

                {data && data.transactionDetails && <div className='dashboard' style={{ padding: "0px 20px 20px 20px" }}>
                    <div className="flex center-left responsive-container">
                        <div className='quick-links' style={{ width: "85%" }}>
                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiMoneyBill className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 l-h-1'>
                                            Total Settlement Amount
                                        </p>
                                        <h6 className='color-primary f-w-700 l-h-1'>
                                            {APP_CURRENCY}{" "}
                                            {allChecked 
                                              ? data?.transactionDetails?.totalCreditAmount != null ? `${formatAmountValue(data.transactionDetails.totalCreditAmount - excludedTxnAmount)}/- ` : `0 /-`
                                              : data?.transactionDetails?.totalCreditAmount != null ? `${formatAmountValue(includedTxnAmount)}/- ` : `0 /-`
                                            } 
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 l-h-1'>
                                            Total Settlement Transaction
                                        </p>
                                        <h6 className='color-primary f-w-700 l-h-1'>
                                            {allChecked 
                                              ? data?.transactionDetails?.totalTransaction != null ? data.transactionDetails.totalTransaction - excludedTxnNo : 0
                                              : data?.transactionDetails?.totalTransaction != null ? includedTxnNo : 0 } Transactions
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="responsive-button" style={{ width: "10%" }} >
                            <RSModal
                                selector={<ButtonLightTextIcon
                                    type="button"
                                    title="Settle"
                                    icon={<CiCircleCheck />}
                                />}
                                content={<SettleView allChecked={allChecked} excludedTxnIds={excludedTxnIds} loading={loading} transactionDetails={data.transactionDetails} setLoading={setLoading} setExcludedTxnIds={setExcludedTxnIds} includedTxnIds={includedTxnIds} setIncludedTxnIds={setIncludedTxnIds} />}
                            />
                        </div>
                    </div>
                </div>
                }

                <div className="results">
                    <div className="rs-table-holder">
                        <table className="rs-table">
                            <thead>
                                <tr>
                                    <th className="flex center-center">
                                        <input
                                            type="checkbox"
                                            checked={allChecked}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Associated acc.</th>
                                    <th>Account no.</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.transactionHistoryResponses?.map((d, i) => {
                                    const formattedDateTime = formatDateTime(d.transactionTime);
                                    return (
                                        <tr key={i} >
                                            <td>
                                                <div className="flex center-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={ allChecked ?  !excludedTxnIds.some((obj) => obj.id === d.id) : includedTxnIds.some((obj) => obj.id === d.id)}
                                                        onChange={() => toggleTxnId(d)}
                                                        value={d.id}
                                                    />
                                                </div>
                                            </td>
                                            <td>{d.associateAccount || "-"}</td>
                                            <td>{d.account || "-"}</td>
                                            <td>{APP_CURRENCY} {formatAmountValue(d.transactionAmount)}/-</td>
                                            <td>{formattedDateTime}</td>
                                            <td>{d.description}</td>
                                        </tr>
                                    );
                                })}
                                {(data?.transactionHistoryResponses.length === 0 || !data) && !loading && (
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: "center", padding: "10px" }} >
                                            <p> No records found. </p>
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                {data && (<Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                    baseUrl={`/settlement?${buildQueryParams({ toDateTime, fromDateTime, clientId, gasStationId })}&`}
                />)}
            </section>
        </section>
    )
}


const SettleView = ({ allChecked, excludedTxnIds, loading, transactionDetails, setLoading, setExcludedTxnIds, setShow , includedTxnIds , setIncludedTxnIds }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { addToastMessage } = useUI();
    const searchParams = new URLSearchParams(location.search);
    const fromDateTime = searchParams.get("fromDateTime") || "";
    const toDateTime = searchParams.get("toDateTime") || "";
    const gasStationId = searchParams.get("gasStationId") || "";
    const clientId = searchParams.get("clientId") || "";

    const handleSettleAction = async () => {
        try {
            setLoading(true);
            const resp = await fetch(API_URL + "/api/1.0.0/settlement", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    excludedTxnIds: allChecked ?  excludedTxnIds.map((d) => (d.id)) : [],
                    includedTxnIds: allChecked ? [] : includedTxnIds.map((d) => (d.id)),
                    gasStationId,
                    startDateTime: fromDateTime.replace(' ', 'T') + ':00:00',
                    endDateTime: toDateTime.replace(' ', 'T') + ':00:00',
                }),
            });
            const data = await resp.json();
            if (resp.ok && data.result.responseCode === 200) {
                navigate(`/settlement?${buildQueryParams({ toDateTime, fromDateTime, clientId, gasStationId })}&pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
                setExcludedTxnIds([]);
                setIncludedTxnIds([]);
                setShow();
                addToastMessage({
                    status: true,
                    message: "You have successfully settled transaction, Thank You!",
                });
            } else {
                const errorData = await resp.json();
                const errorMessage = errorData.responseDescription;
                addToastMessage({
                    status: false,
                    message: errorMessage || "Something went wrong while settled transaction. Please try again!",
                });
            }
        } catch (e) {
            console.log(e);
            addToastMessage({
                status: false,
                message: "Something went wrong while settled transaction. Please try again!",
            });
        } finally {
            setLoading(false);
        }
    }

    const { amount: excludedTxnAmount, count: excludedTxnNo } = calculateTransactionStats(excludedTxnIds);
    const { amount: includedTxnAmount, count: includedTxnNo } = calculateTransactionStats(includedTxnIds);

    return (
        <div
            style={{
                height: "auto",
                width: "auto",
                padding: "40px",
            }}
        >
            <h4 className="color-primary">Settlement Details</h4>
            <br />
            <br />
            <div
                style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                }}
            >
                <section>
                    {transactionDetails && <div className='dashboard' style={{ padding: "0px 20px 20px 20px" }}>
                        <div className='quick-links'>
                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiMoneyBill className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 l-h-1'>
                                            Total Settlement Amount
                                        </p>
                                        <h5 className='color-primary f-w-700 l-h-1'>
                                            {APP_CURRENCY}{" "}
                                            {allChecked 
                                              ? transactionDetails?.totalCreditAmount != null ? `${formatAmountValue(transactionDetails.totalCreditAmount - excludedTxnAmount)}/- ` : `0 /-`
                                              : transactionDetails?.totalCreditAmount != null ? `${formatAmountValue(includedTxnAmount)}/- ` : `0 /-`}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className='one flex center-left'>
                                <div className='flex center-left' style={{ gap: "10px" }}>
                                    <CiFileOn className='color-secondary' style={{ height: "40px", width: "auto" }} />
                                    <div>
                                        <p className='color-faded font-size-12 f-w-400 l-h-1'>
                                            Total Transaction
                                        </p>
                                        <h5 className='color-primary f-w-700 l-h-1'>
                                            {allChecked  
                                              ? transactionDetails?.totalTransaction != null ? transactionDetails.totalTransaction - excludedTxnNo : 0
                                              : transactionDetails?.totalTransaction != null ? includedTxnNo : 0 } Transactions
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </section>
            </div>
            <br />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "12px",
                }}
            >
                <ButtonLightTextIcon
                    title="Go back"
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26.562"
                            height="17.809"
                            viewBox="0 0 26.562 17.809"
                        >
                            <path d="M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z" />
                        </svg>
                    }
                    onClick={() => setShow(false)}
                />
                {loading ? (
                    <ButtonTextIcon
                        type='button'
                        title='Settling...'
                        disabled={loading}
                        icon={<LoaderIcon />}
                    />
                ) : (
                    <ButtonTextOnly
                        title={"Settle"}
                        type='submit'
                        disabled={!(transactionDetails && transactionDetails?.totalTransaction && (allChecked ?  transactionDetails.totalTransaction - excludedTxnNo : includedTxnNo) )}
                        onClick={handleSettleAction}
                    />
                )}
            </div>
        </div>
    );
}

const formatDateToCustomString = (date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}`;
};


const parseDateString = (dateString) => {
    const [datePart, hourPart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
    const hour = parseInt(hourPart, 10);

    return new Date(year, month - 1, day, hour);
};

  const DateTimePickerField = ({ field, form, ...props }) => {
    const { setFieldValue } = form;

    const handleChange = (date) => {
      if (date) {
        setFieldValue(field.name, formatDateToCustomString(date));
      } else {
        setFieldValue(field.name, ''); 
      }
    };

    const selectedDate = field.value ? parseDateString(field.value) : null;

    return (
      <DatePicker
        {...field}
        {...props}
        selected={selectedDate}
        onChange={handleChange}
        showTimeSelect
        timeIntervals={60}  
        dateFormat="yyyy-MM-dd HH"  
        placeholderText={props.placeholder}
      />
    );
  };

 
export default Settlement;