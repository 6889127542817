import { Fragment, useEffect, useState } from "react";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from "react-router-dom";
import {
    API_URL,
    DEFAULT_PER_PAGE,
} from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import {
    CiBitcoin,
    CiHome,
    CiUser,
    CiCircleCheck,
    CiLocationArrow1,
    CiCircleRemove,
} from "react-icons/ci";
import LoaderIcon from "../../components/ui/LoaderIcon";
import FileInput from "../../components/ui/FileInput";
import { FaEye, FaEyeSlash, FaRegEyeSlash } from 'react-icons/fa';

const AddClient = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {
        setNewNotification,
        setShowNotification,
        addNotification,
        setSegmentTitle,
        addToastMessage,
    } = useUI();

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    useEffect(() => {
        setSegmentTitle("Add Client");
    }, []);

    const ValidationSchema = Yup.object().shape({
        adminEmail: Yup.string()
            .email("Invalid Email")
            .required("Admin email is required"),
        adminFullName: Yup.string().required("Admin's Full name is required"),
        adminPhoneNo: Yup.string()
            .required("Phone number is required!")
            .matches(/^0\d{9}$/, "The phone number must start with 0 and be followed by 9 digits")
            .min(10, "The number should have 10 digits")
            .max(10, "The number should have 10 digits"),
        name: Yup.string().required("Name is required"),
        address: Yup.string().required("Address is required"),
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required!'),
        // confirmPassword: Yup.string().required("Confirm Password is required"),

    });

    const initial = {
        adminFullName: "",
        adminEmail: "",
        adminPhoneNo: "",
        password: "",
        name: "",
        address: "",
        confirmPassword: "",

    };

    const handleCreateClient = async (value) => {
        setLoading(true);
        try {
            const request = {
                name: value.name,
                address: value.address,
                adminDetails: {
                    adminFullName: value.adminFullName,
                    adminEmail: value.adminEmail,
                    adminPhoneNo: value.adminPhoneNo,
                    password: value.password,
                },
            };

            const resp = await fetch(API_URL + "/api/1.0.0/client", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body: JSON.stringify(request),
            });

            if (resp.ok) {
                addToastMessage({
                    status: true,
                    message: "You have successfully created a client.",
                });
                setNewNotification(true);
                setShowNotification(true);
                addNotification({
                    id: Date.now(),
                    message: "You have successfully created a client!",
                    status: true,
                    archive: false,
                });
                navigate(
                    `/client?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`
                );
            } else {
                const errorData = await resp.json();
                const errorMessage = errorData.responseDescription;
                addToastMessage({
                    status: false,
                    message:
                        errorMessage ||
                        "Something went wrong while creating a Client. Please try again.",
                });
            }
        } catch (e) {
            setNewNotification(true);
            setShowNotification(true);
            addNotification({
                id: Date.now(),
                message:
                    "Something went wrong when creating a Client!",
                status: false,
                archive: false,
            });
        } finally {
            setLoading(false);
        }
    };



    const SingleStep = ({ title, subtitle, completion }) => {
        const getClassName = () => {
            let hasEmptyElement = false;
            if (Array.isArray(completion)) {
                hasEmptyElement = completion.some((element) => element === "");
            } else {
                hasEmptyElement = completion().some(
                    (element) => element === ""
                );
            }

            if (hasEmptyElement) {
                return "incomplete";
            } else {
                return "complete";
            }
        };

        return (
            <div className={`step t-a-c ${getClassName()}`}>
                <p
                    className='color-primary'
                    style={{ fontSize: "12px", marginBottom: "5px" }}>
                    <span className='f-w-700'>0{title}</span> {subtitle}
                </p>
                <div className={`b-r-100 icon`}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'>
                        <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
                    </svg>
                </div>
            </div>
        );
    };

    const Row = ({ children }) => {
        return (
            <div className='row b-r-8'>
                <div className='col-1-1 wrap flex'>{children}</div>
            </div>
        );
    };

    const SectionTitle = ({ title, subtitle, icon }) => {
        return (
            <div className='col-1-2'>
                <div
                    className='flex center-left'
                    style={{
                        gap: "12px",
                    }}>
                    {icon}
                    <div className='flex center-left'>
                        <div>
                            <p className='f-w-700 l-h-1-2 color-primary'>
                                {title}
                            </p>
                            <p className='l-h-1-2 color-light small'>
                                {subtitle}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const SectionForm = ({ children }) => {
        return (
            <div className='col-1-2'>
                <table>
                    <thead></thead>
                    <tbody>{children}</tbody>
                </table>
            </div>
        );
    };

    const FieldTitle = ({ title }) => {
        return (
            <p
                className='title f-w-700 color-primary'
                style={{ marginBottom: "5px", fontSize: "14px" }}>
                {title}
            </p>
        );
    };

    useEffect(() => {
        const onScroll = () => {
            const elem = document.getElementById("stepper-should-fix");
            const impact = document.getElementById("stepper-should-impact");
            if (elem && impact) {
                if (window.scrollY > 92) {
                    elem.classList.add("loan-application-stepper-fixed-position");
                    impact.classList.add("stepper-should-impact-fixed-position");
                } else {
                    elem.classList.remove(
                        "loan-application-stepper-fixed-position"
                    );
                    impact.classList.remove("stepper-should-impact-fixed-position");
                }
            }
        };
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    });

    function capitalizeFirstLetter(str) {
        return str[0].toUpperCase() + str.slice(1);
    }

    return (
        <section>
            <PageTitle title='Add Client' />
            <FullPageLoader isActive={loading} />
            <Formik
                initialValues={initial}
                validationSchema={ValidationSchema}
                onSubmit={async (values, { validateForm }) => {
                    handleCreateClient(values);
                }}>
                {({
                    values,
                    errors,
                    touched,
                    validateForm,
                    setFieldValue,
                    resetForm,
                    handleSubmit,
                }) => (
                    <Form>
                        <section>
                            <section
                                id='stepper-should-fix'
                                className='loan-application-stepper'>
                                <div
                                    className='steps flex center-center'
                                    style={{ gap: "20px" }}>
                                    <SingleStep
                                        title='1'
                                        subtitle='Admin Info'
                                        completion={[
                                            values.adminEmail,
                                            values.adminPhoneNo,
                                            values.adminFullName,
                                            values.adminAddress,
                                            values.password,
                                        ]}
                                    />
                                    <SingleStep
                                        title='2'
                                        subtitle='Client details'
                                        completion={[
                                            values.name,
                                            values.address,
                                        ]}
                                    />
                                </div>
                            </section>
                            <section
                                id='stepper-should-impact'
                                className='pad-20 create-application'>
                                <Row>
                                    <SectionTitle
                                        title='Admin Information'
                                        subtitle="Enter basic admin's information"
                                        icon={
                                            <CiUser
                                                className='color-primary'
                                                style={{
                                                    height: "32px",
                                                    width: "32px",
                                                    strokeWidth: "1px",
                                                }}
                                            />
                                        }
                                    />
                                    <SectionForm>
                                        <tr>
                                            <td colSpan='2'>
                                                <FieldTitle title='Full Name' />
                                                <Field
                                                    name='adminFullName'
                                                    type='text'
                                                    placeholder='Eg: Jake *'
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.adminFullName &&
                                                            touched.adminFullName
                                                            ? " error"
                                                            : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    name='adminFullName'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width='120'>
                                                <FieldTitle title='Phone' />
                                                <Field
                                                    as='select'
                                                    name='adminCountryCode'
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.adminCountryCode &&
                                                            touched.adminCountryCode
                                                            ? " error"
                                                            : "")
                                                    }>
                                                    <option value='+27'>
                                                        +27
                                                    </option>
                                                </Field>
                                            </td>
                                            <td>
                                                <FieldTitle title='&nbsp;' />
                                                <Field
                                                    name='adminPhoneNo'
                                                    type='text'
                                                    placeholder='Eg 0763276589 *'
                                                    maxLength={10}
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.adminPhoneNo &&
                                                            touched.adminPhoneNo
                                                            ? " error"
                                                            : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    name='adminPhoneNo'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan='2'>
                                                <FieldTitle title='Email' />
                                                <Field
                                                    name='adminEmail'
                                                    type='text'
                                                    placeholder='Eg: john.doe@xyz.com *'
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.adminEmail &&
                                                            touched.adminEmail
                                                            ? " error"
                                                            : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    name='adminEmail'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="2">
                                                <p className="title f-w-700 color-primary">Password:</p>
                                                <div className="password-field-wrapper">
                                                    <Field
                                                        name="password"
                                                        type={newPasswordVisible ? 'text' : 'password'}
                                                        placeholder="**********"
                                                        className={
                                                            " " + (errors.password && touched.password ? "error" : "")
                                                        }
                                                    />
                                                    <span
                                                        onClick={toggleNewPasswordVisibility}
                                                        className="eye-icon"
                                                        aria-label={newPasswordVisible ? 'Hide password' : 'Show password'}
                                                    >
                                                        {newPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <p className="title f-w-700 color-primary">Confirm Password:</p>
                                                <div className="password-field-wrapper">
                                                    <Field
                                                        name="confirmPassword"
                                                        type={confirmPasswordVisible ? 'text' : 'password'}
                                                        placeholder="**********"
                                                        className={
                                                            " " + (errors.confirmPassword && touched.confirmPassword ? "error" : "")
                                                        }
                                                    />
                                                    <span
                                                        onClick={toggleConfirmPasswordVisibility}
                                                        className="eye-icon"
                                                        aria-label={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                                                    >
                                                        {confirmPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
                                                    </span>
                                                </div>
                                                <ErrorMessage
                                                    name='confirmPassword'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>
                                    </SectionForm>
                                </Row>
                                <br />
                                <Row>
                                    <SectionTitle
                                        title='Client Details'
                                        subtitle='Enter basic details of the Client'
                                        icon={
                                            <CiHome
                                                className='color-primary'
                                                style={{
                                                    height: "32px",
                                                    width: "32px",
                                                    strokeWidth: "1px",
                                                }}
                                            />
                                        }
                                    />
                                    <SectionForm>
                                        <tr>
                                            <td colSpan='2'>
                                                <FieldTitle title=' Name' />
                                                <Field
                                                    name='name'
                                                    type='text'
                                                    placeholder='Eg: Bp Randburg *'
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.name &&
                                                            touched.name
                                                            ? " error"
                                                            : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    name='name'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan='2'>
                                                <FieldTitle title='Address' />
                                                <Field
                                                    name='address'
                                                    type='text'
                                                    placeholder='Eg:  12 Street, City, Country *'
                                                    className={
                                                        "col-1-1 " +
                                                        (errors.address &&
                                                            touched.address
                                                            ? " error"
                                                            : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    name='address'
                                                    component='p'
                                                    className='error-messages'
                                                />
                                            </td>
                                        </tr>
                                    </SectionForm>
                                </Row>
                                <br />
                                <br />
                                <div
                                    className='flex center-center'
                                    style={{ gap: "12px" }}>
                                    <NavLink
                                        to={`/client?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
                                        <ButtonLightTextIcon
                                            type='button'
                                            title='Cancel'
                                        />
                                    </NavLink>
                                    {loading ? (
                                        <ButtonTextIcon
                                            type='button'
                                            title='Creating...'
                                            disabled={loading}
                                            icon={<LoaderIcon />}
                                        />
                                    ) : (
                                        <ButtonTextOnly
                                            type='submit'
                                            // disabled={
                                            // 	loading || !stampUploadURL
                                            // }
                                            title='Create Now'
                                        />
                                    )}
                                </div>
                            </section>
                        </section>
                    </Form>
                )}
            </Formik>
        </section>
    );
};

export default AddClient;
