import { useEffect } from "react";

const PageTitle = ({ title }) => {
	useEffect(() => {
		const originalTitle = document.title;
		if (title) {
			document.title = title + " | Gasolina.com";
		}
		return () => {
			document.title = originalTitle;
		};
	}, []);

	return <></>;
};

export default PageTitle;
