import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/ui/SearchBar";
import { API_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";

const AddGasStation = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const token = localStorage.getItem("token");
	const location = useLocation();
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const ValidationSchema = Yup.object().shape({
    address: Yup.string()
      .required("Address is required!")
      .matches(/^[^_]*$/, "Address must not contain underscores!"),
    name: Yup.string()
      .required("Site Name is required")
      .matches(/^[^_]*$/, "Site Name must not contain underscores!"),
    // clientId: Yup.string().required("Client Name is required"),
  });

	const initial = {
		address: "",
		name: "",
		clientId: "",
	};

	const handleAddGasStation = async (value) => {
		setLoading(true);
		try {
			const request = {
				address: value.address,
				name: value.name,
				clientId: value?.clientId?value?.clientId:null,
			};

			const resp = await fetch(API_URL + "/api/1.0.0/gasstation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify(request),
			});

			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully created a site, Thank You!",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a site!",
					status: true,
					archive: false,
				});
				navigate(`/sites?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
			}
			else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while creating a site. Please try again!",
				});
			}
		} catch (e) {
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a site.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// callClientListApi();
		setSegmentTitle("Add a Site");
	}, []);

	return (
		<section>
			<PageTitle title='Add Site' />
			<FullPageLoader isActive={loading} />
			<section className='create-station'>
				<h5 className='color-primary'>Add Details</h5>
				<p>Enter the site details below:</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={initial}
						validationSchema={ValidationSchema}
						onSubmit={async (values, { validateForm }) => {
							await handleAddGasStation(values);
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										{/* <tr>
											<td colSpan='6'>
												<Field
													name='clientId'
													type='text'
													component='select'
													className={
														" " +
														(errors.clientId &&
															touched.clientId
															? " error"
															: "")
													}>
													<option value=''>
														Select Client
													</option>
													{data &&
														data.clients.map(
															(c, i) => (
																<option
																	value={c.id}
																	key={i}>
																	{c.name}
																</option>
															)
														)}
												</Field>
												<ErrorMessage
													name='clientId'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr> */}
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Name
												</p>
												<Field
													name='name'
													type='text'
													placeholder='Eg: Fuel Junction.'
													className={
														"col-1-1 " +
														(errors.name &&
															touched.name
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='name'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Address
												</p>
												<Field
													name='address'
													type='text'
													placeholder='Eg: 12 Street, City, Country'
													className={
														"col-1-1 " +
														(errors.address &&
															touched.address
															? " error"
															: "")
													}
												/>
												<ErrorMessage
													name='address'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<br />
										<tr>
											<td
												colSpan='6'
												className='btns t-a-r'>
												<NavLink
													to={`/sites?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon
														type='button'
														title='Cancel'
													/>
												</NavLink>
												<ButtonTextOnly
													type='submit'
													title='Create Now'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};
export default AddGasStation;
