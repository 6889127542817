// ====== APPLICATION CONSTANTS ====== //
export const API_URL = process.env.REACT_APP_API_BASE_URL;
// export const API_URL = "http://192.168.1.212:8081";//"http://localhost:8081";//"http://192.168.1.14:8081";//
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SYSTEM_TOKEN = process.env.REACT_APP_SYSTEM_TOKEN;
// export const SYSTEM_TOKEN = "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjEsInVzZXJOYW1lIjoiMTIzNDU2NzYwMCIsImF1dGhvcml0aWVzIjpbMV0sImlzcyI6Im9jdG9waS1sYWJzLmNvbSIsImV4cCI6MjIzNTk5ODM3NCwiaWF0IjoxNzE3NTk4Mzc0fQ.RA1FOnKfu3lTJh74jpoVQuEVz5qsoElP5RdMp1oaOy8";
export const RS_TAGLINE = " Fuel Up with Ease";//" Solution for Fuel Stations";//"Solutions for Gas Stations in South Africa.";
export const DEFAULT_PER_PAGE = 10;
export const APP_ROLES = [
	{
		roleId: 1,
		// title: "Admin",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Gasolina Super Admin",
	},
	{
		roleId: 2,
		title: "Sub Admin",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Gasolina Sub Admin",
	},
	{
		roleId: 3,
		title: "Financier",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Financier",
	},
	{
		roleId: 4,
		title: "Petrol Attendant",
		context: "gu",
		defaultURL: "/dashboard",
		titleBeautify: "Petrol Attendant",
	},
	{
		roleId: 5,
		title: "Customer",
		context: "gu",
		defaultURL: "/dashboard",
		titleBeautify: "Customer",
		
	},
	{
		roleId: 6,
		title: "Client Admin",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Client Admin",
	},
	{
		roleId: 7,
		title: "Cashier",
		context: "gu",
		defaultURL: "/dashboard",
		titleBeautify: "Cashier",
	},
	{
		roleId: 8,
		title: "Client Sub Admin",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Client Sub Admin",
	},
	{
		roleId: 9,
		title: "Gasolina Finance Manager",
		context: "ga",
		defaultURL: "/dashboard",
		titleBeautify: "Gasolina Finance Manager",
	},
	{
		roleId: 10,
		title: "Site Manager",
		context: "gu",
		defaultURL: "/dashboard",
		titleBeautify: "Site Manager",
	}
];

export const APP_CURRENCY = "ZAR";
