import { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import { useUI } from "../../context/ui";
import { API_URL, APP_CURRENCY } from "../../utilities/constants";
import PageTitle from "../../components/common/PageTitle";

const WalletTopUp = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const { setNewNotification, setShowNotification, addNotification, addToastMessage } =
		useUI();

	const ValidationSchema = Yup.object().shape({
		amount: Yup.string()
			.matches(/^\d+(\.\d{1,2})?$/, "Amount must be a valid number with up to two decimal places.")
			.test("not-zero", "Amount must be greater than 0.", (value) => parseFloat(value) > 0)
			.required("Amount is required"),
	});

	const initial = {
		amount: "",
	};

	const handleCustomerWallet = async (values, { resetForm }) => {
		setLoading(true);
		try {
			const request = {
				amount: values.amount,
			};

			const resp = await fetch(API_URL + "/ocpl/fund-super-account", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify(request),
			});
			if (resp.ok) {
				addToastMessage({
					status: true,
					message:
						"Your super wallet topup is succefully completed, thank you!",
				});
				resetForm();
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully completed the top-up!",
					status: true,
					archive: false,
				});
				// navigate(`/transactions`);
			}
			else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while performing super wallet topup. Please try again!",
				});
			}
		} catch (e) {
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Something went wrong while doing Top Up.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section>
			<PageTitle title='Wallet' />
			<FullPageLoader isActive={loading} />
			<section className='create-station'>
				<h5 className='color-primary'>Topup Details</h5>
				<p>Enter the wallet Topup details below:</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={{ ...initial }}
						validationSchema={ValidationSchema}
						onSubmit={async (values, { resetForm, validateForm }) => {
							await handleCustomerWallet(values, { resetForm });
						}}>
						{({
							values,
							errors,
							touched,
							validateForm,
							setFieldValue,
							resetForm,
							handleSubmit,
						}) => (
							<Form>
								<table>
									<thead></thead>
									<tbody>
										<tr>
											<td colSpan='6'>
												<p className='title f-w-700 color-primary'>
													Enter Amount in{" "}
													{APP_CURRENCY}
												</p>
												<Field
													name='amount'
													type='text'
													placeholder='Eg: 1200'
													className={
														"col-1-1 " +
														(errors.amount &&
															touched.amount
															? " error"
															: "")
													}
												/>
												{errors.amount && <p className='error-messages' >{errors.amount}
												</p>  }
												{/* <ErrorMessage
													name='amount'
													component='p'
													className='error-messages'
												/> */}
											</td>
										</tr>
										<br />
										<tr>
											<td
												colSpan='6'
												className='btns t-a-r'>
												<ButtonTextOnly
													disabled={loading}
													type='submit'
													title='Submit'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};

export default WalletTopUp;
