import { CiCircleRemove, CiCirclePlus, CiBarcode, CiSearch, CiRedo, CiCircleCheck, CiInboxIn } from "react-icons/ci";
import PageTitle from "../../components/common/PageTitle";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken, formatAmountValue, formatDateTime } from "../../utilities/helpers";
import { useEffect, useState, useRef, useCallback } from "react";
import { API_URL, APP_CURRENCY, BASE_URL, DEFAULT_PER_PAGE } from "../../utilities/constants";
import { useUI } from "../../context/ui";
import Pagination from "../../components/common/Pagination";
import FullPageLoader from "../../components/ui/FullPageLoader";
import ResultsPerPage from "../_common/ResultsPerPage";
import RSModal from "../../components/ui/RSModal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import { useFetch } from "../../hooks/useFetch";


const buildQueryParams = (params) => Object.entries(params)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const SettlementHistory = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingClient, setLoadingClient] = useState(false);
    const [loadingSite, setLoadingSite] = useState(false);
    const [data, setData] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [gasStationsData, setGasStationsData] = useState(null);
    const [userClientId, setUserClientId] = useState("");
    const { setSegmentTitle } = useUI();
    const userRole = decodeRolesFromToken()[0];

    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get("pageNo") || 0;
    const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
    const fromDate = searchParams.get("fromDate") || "";
    const toDate = searchParams.get("toDate") || "";
    const clientId = searchParams.get("clientId") || "";
    const gasStationId = searchParams.get("gasStationId") || "";

    const { data: userData } = useFetch(API_URL + "/api/1.0.0/user/profile", { method: "GET", });

    useEffect(() => {
        if (userData) {
            setUserClientId(userData.data.clientId || "");
        }
    }, [userData]);

    const fetchData = useCallback(async (endpoint, setState, setLoading) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}${endpoint}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.result.responseCode === 200) {
                    setState(data.data || null);
                } else {
                    setState(null);
                }
            } else {
                setState(null);
            }
        } catch (e) {
            console.error("Error fetching data", e);
            setState(null);
        } finally {
            setLoading(false);
        }
    }, []);

    const callApi = useCallback((filterData) => {
        const queryParams = buildQueryParams({
            sortOrder: "desc",
            endDate: toDate,
            startDate: fromDate,
            clientId : [6, 8, 3].includes(userRole) ? "" : clientId,
            gasStationId,
            pageNo,
            pageSize,
        });
        fetchData(`/api/1.0.0/settlement/settled-txn?${queryParams}`, (data) => { setData(data); }, setLoading);
    }, [pageNo, pageSize, fromDate, toDate, clientId, gasStationId]);

    useEffect(() => {
        callApi();
        setSegmentTitle("Settlements");
    }, [location.search])

    useEffect(() => {
        fetchData("/api/1.0.0/client?pageNo=0&pageSize=100&sortOrder=desc", setClientData, setLoadingClient);
    }, [fetchData]);

    useEffect(() => {
        if (clientId || userClientId) {
            fetchData(`/api/1.0.0/gasstation?pageNo=0&pageSize=100&sortOrder=desc&clientID=${clientId || userClientId}`, setGasStationsData, setLoadingSite);
        }
    }, [clientId, userClientId, fetchData]);

    const handleClientChange = async (event) => {
        const clientIdValue = event.target.value
        const queryParams = buildQueryParams({
            fromDate, toDate,
            pageNo: 0, pageSize,
            clientId: clientIdValue,
        });
        navigate(`/settlement-history?${queryParams}`);
    };

    const handleGasStationChange = (event) => {
        const gasStationIdValue = event.target.value;
        const queryParams = buildQueryParams({
            fromDate, toDate,
            clientId, gasStationId: gasStationIdValue,
            pageNo: 0, pageSize,
        });
        navigate(`/settlement-history?${queryParams}`);
    };

    const resetFilter = () => { navigate(`/settlement-history?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`); }


  const downloadSettlementReport = async (settlementId,refnumber) => {
      try {
        setLoading(true);
      const response = await fetch(
        `${API_URL}/api/1.0.0/settlement/settlement-report/download?settlementId=${settlementId}`,
        {
          headers: {
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );

      if (!response.ok) throw new Error("Failed to download settlement report");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `settlement_report_${refnumber}.xlsx`);
      document.body.appendChild(link);
      link.click();

      // Cleanup: Revoke object URL and remove link
      link.remove();
          window.URL.revokeObjectURL(url);
            setLoading(false);
    } catch (error) {
      console.error("Error during file download:", error);
      } finally {
            setLoading(false);
        }
    };
    
    return (<section className="pos-rel">
        <PageTitle title="Settlements" />
        <FullPageLoader isActive={loading || loadingClient || loadingSite} />
        <section>
            <div className="flex center-left responsive-container">
                <ResultsPerPage targetURL={`/settlement-history?${buildQueryParams({ clientId, gasStationId, toDate, fromDate, pageNo: 0 })}&pageSize=`} />
                <select onChange={handleClientChange} className="responsive-select" value={clientId}
                disabled={[6, 8, 3].includes(userRole)}
                style={{
                    display : [6, 8, 3].includes(userRole) && "none"
                }}
                >
                    {![6, 8, 3].includes(userRole)  && <option value="">All Client</option>}
                    {clientData?.clients?.map((client, i) => {
                        if([6, 8, 3].includes(userRole) ){
                            if(client.id === userClientId){
                                return <option key={i} value={client.id}>{client.name}</option>
                            }
                        }else{
                            return <option key={i} value={client.id}>{client.name}</option>
                        }
                    }
                    )}
                </select>

                <select onChange={handleGasStationChange} className="responsive-select" value={gasStationId}>
                    <option value="">All Site</option>
                    {gasStationsData?.gasStations?.map((station, i) => (
                        <option key={i} value={station.id}>{station.name}</option>
                    ))}
                </select>

                <div>
                    <Formik
                        initialValues={{ fromDate: fromDate, toDate: toDate, }}
                        validationSchema={Yup.object().shape({
                            fromDate: Yup.date().required("End date is required"),
                            toDate: Yup.date().required("End date is required").min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
                        })}
                        enableReinitialize
                        onSubmit={async (values, { resetForm }) => {
                            navigate(`/settlement-history?${buildQueryParams({ clientId, gasStationId, pageNo: 0, pageSize })}&fromDate=${values.fromDate}&toDate=${values.toDate}`);
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            resetForm,
                        }) => (
                            <Form>
                                <div
                                    className='flex center-center'
                                    style={{ gap: "12px" }}>
                                    <Field
                                        name='fromDate'
                                        type='date'
                                        className={" " + (errors.fromDate && touched.fromDate ? " error" : "")}
                                    />
                                    <Field
                                        name='toDate'
                                        type='date'
                                        className={" " + (errors.toDate && touched.toDate ? " error" : "")}
                                    />
                                    <ButtonTextIcon title="Search" type='submit' icon={<CiSearch />} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="responsive-button">
                    <ButtonTextIcon
                        title="Reset"
                        type='button'
                        onClick={resetFilter}
                        icon={<CiRedo />}
                    />
                </div>
            </div>
            <div className="results">
                <div className="rs-table-holder">
                    {data && (
                        <table className="rs-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Ref No.</th>
                                    <th>Transaction No.</th>
                                    <th>Amount</th>
                                    <th>Total Transaction</th>
                                    {/* <th>Client Name</th> */}
                                    <th>Site Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.settlementList.map((d, i) => {
                                    // const formattedDateTime = formatDateTime(d.transactionTime);
                                    return (
                                        <tr
                                            key={i}
                                            // style={{ backgroundColor: `${d.transactionType === "CREDIT" ? "#EAF8F4" : "#FFEBEB"}`,}}
                                        >
                                            <td>
                                                {data.pageNo * data.pageSize + i + 1}
                                            </td>
                                            <td>{d.referenceId || "-"}</td>
                                            <td>{d.oplTransactionGroupId}</td>
                                            <td>{APP_CURRENCY} {d.amount ? formatAmountValue(d.amount) : "0"}/-</td>
                                            <td>{d.numberOfTransactions}</td>
                                            {/* <td>{d.clientName}</td> */}
                                            <td>{d.gasStation}</td>
                                            <td>{formatDateTime(d.startDateTime)}</td>
                                            <td>{formatDateTime(d.endDateTime)}</td>
                                            <td>{formatDateTime(d.createdAt)}</td>
                                            <td>
                                                <div className="flex center-center" style={{ gap: "12px", }} >
                                                    <Link to={`/settlements-txn/${d.id}?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="14"
                                                            viewBox="0 0 20 14"
                                                            style={{ height: "16px", width: "16px", }}
                                                        >
                                                            <path
                                                                d="M12,5C7.92,5,4.256,8.093,2.145,11.483a1,1,0,0,0,0,1.034C4.256,15.907,7.92,19,12,19c4.143,0,7.8-3.09,9.864-6.493a1,1,0,0,0,0-1.014C19.794,8.09,16.141,5,12,5Zm8.413,7c-1.837,2.878-4.9,5.5-8.413,5.5-3.465,0-6.532-2.632-8.4-5.5C5.465,9.132,8.533,6.5,12,6.5,15.516,6.5,18.577,9.124,20.411,12ZM12,8a4,4,0,1,1-4,4A4,4,0,0,1,12,8Zm0,1.5A2.5,2.5,0,1,0,14.5,12,2.5,2.5,0,0,0,12,9.5Z"
                                                                transform="translate(-2 -5)"
                                                            />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className="flex center-center"
                                                    style={{ gap: "12px" }}
                                                    onClick={() => downloadSettlementReport(d.id,d.referenceId )}
                                                >
                                                    <CiInboxIn />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {data?.settlementList.length === 0 && !loading && (
                                    <tr>
                                        <td colSpan={10} style={{ textAlign: "center", padding: "10px" }} >
                                            <p> No records found. </p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            <br />
            {data && (<Pagination
                totalRecords={data.totalRecords}
                pageNo={data.pageNo}
                pageSize={data.pageSize}
                baseUrl={`/settlement-history?${buildQueryParams({ clientId, gasStationId, pageSize })}&`}
            />)}

        </section>
    </section>);
}

export default SettlementHistory;