import "./css/globals.scss";
import "./css/base.scss";
import "./css/pages.scss";
import "./css/responsive.scss";

import { UIProvider } from "./context/ui";

import { Route, Routes } from "react-router-dom";

// ====== Contexts & HOCs ====== //

import RoleBasedComponent from "./context/roles";

// ====== Common Pages ====== //

import Login from "./pages/_common/Login";

// =======dashboard ==============//

import AdminDashboard from "./pages/gasoline_admin/AdminDashboard";
import GasStation from "./pages/gasoline_admin/GasStation";
import AddGasStation from "./pages/gasoline_admin/AddGasStation";
import TransactionHistory from "./pages/gasoline_admin/TransactionHistory";
import Profile from "./pages/_common/Profile";
import Users from "./pages/gasoline_admin/Users";
import AddUser from "./pages/gasoline_admin/AddUser";
import AdminWallet from "./pages/gasoline_admin/AdminWallet";
import OperatorDashboard from "./pages/gasoline_user/OperatorDashboard";
import CustomerList from "./pages/gasoline_admin/CustomerList";
import WalletTopUp from "./pages/gasoline_admin/WalletTopUp";
import ForgotPassword from "./pages/_common/ForgotPassword";
import ResetPassword from './pages/_common/ResetPassword'
import LinkCode from "./pages/_common/LinkCode";
import Client from "./pages/gasoline_admin/Client";
import AddClient from "./pages/gasoline_admin/AddClient";
import AddGasolinaSubAdmin from "./pages/gasoline_admin/AddGasilnaSubAdmin";
import SiteConfig from "./pages/gasoline_admin/SiteConfig";
import Settlement from "./pages/gasoline_admin/Settlement";
import SettlementHistory from "./pages/gasoline_admin/SettlementHistory";
import SettlementTxnReport from "./pages/gasoline_admin/SettlementTxnReport";
import ReconciliationReport from "./pages/gasoline_admin/ReconciliationReport";
// ====== App Routes ====== //

const App = () => {
	return (
		<UIProvider>
			<Routes>
				<Route exact path='/' element={<Login />} />
				<Route path="/reset-password" element={<ResetPassword />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path="/link" element={<LinkCode />} />

				<Route
					path='/dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2, 3, 6, 8, 9, 10]}
							component={AdminDashboard}
						/>
					}
				/>
				{/* <Route
					path='/gu-dashboard'
					element={
						<RoleBasedComponent
							allowedRoles={[3]}
							component={OperatorDashboard}
						/>
					}
				/> */}
				<Route
					path='/profile'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2, 3, 4, 6, 7, 8 , 9, 10]}
							component={Profile}
						/>
					}
				/>
				<Route
					path='/client'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2,]}
							component={Client}
						/>
					}
				/>
				<Route
					path='/client/add'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2]}
							component={AddClient}
						/>
					}
				/>
				<Route
					path='/sites'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2, 6, 8]}
							component={GasStation}
						/>
					}
				/>
				<Route
					path='/sites/add'
					element={
						<RoleBasedComponent
							allowedRoles={[1,6, 8]}
							component={AddGasStation}
						/>
					}
				/>
				<Route
					path='/users'
					element={
						<RoleBasedComponent 
						allowedRoles={[1, 2, 6, 8]} 
						component={Users} />
					}
				/>
				<Route
					path='/users/add'
					element={
						<RoleBasedComponent
							allowedRoles={[2, 6, 8]}
							component={AddUser}
						/>
					}
				/>
				<Route
					path='/users/add-subadmin'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={AddGasolinaSubAdmin}
						/>
					}
				/>
				<Route
					path='/customers'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={CustomerList}
						/>
					}
				/>
				<Route
					path='/transactions'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 2, 3, 4, 6, 7, 8, 9, 10]}
							component={TransactionHistory}
						/>
					}
				/>
				<Route
					path='/wallet'
					element={
						<RoleBasedComponent
							allowedRoles={[1, 9]}
							component={AdminWallet}
						/>
					}
				/>
				<Route
					path='/wallet-topup'
					element={
						<RoleBasedComponent
							allowedRoles={[1]}
							component={WalletTopUp}
						/>
					}
				/>
				<Route
					path='/site-config'
					element={
						<RoleBasedComponent
							allowedRoles={[10]}
							component={SiteConfig}
						/>
					}
				/>
				<Route 
				    path="/settlement"
					element={
						<RoleBasedComponent
							allowedRoles={[ 9]}
							component={Settlement}
						/>
					}
				/>
				<Route 
				    path="/settlement-history"
					element={
						<RoleBasedComponent
							allowedRoles={[1, 9 , 2 ,6 ,8 ,3]}
							component={SettlementHistory}
						/>
					}
				/>
				<Route 
				    path="/settlements-txn/:id"
					element={
						<RoleBasedComponent
							allowedRoles={[1, 9 , 2 ,6 ,8 ,3]}
							component={SettlementTxnReport}
						/>
					}
				/>
				<Route 
				    path="/reconciliation-report"
					element={
						<RoleBasedComponent
							allowedRoles={[1, 9 , 2]}
							component={ReconciliationReport}
						/>
					}
				/>
			</Routes>
		</UIProvider>
	);
};
export default App;
