import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { API_URL, DEFAULT_PER_PAGE, SYSTEM_TOKEN } from "../../utilities/constants";
import ButtonLightTextIcon from "../../components/ui/ButtonLightTextIcon";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';


const AddGasolinaSubAdmin = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [clientdata, setClientData] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem("token");
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const toggleNewPasswordVisibility = () => {
		setNewPasswordVisible(!newPasswordVisible);
	};

	const toggleConfirmPasswordVisibility = () => {
		setConfirmPasswordVisible(!confirmPasswordVisible);
	};


	const validationSchema = Yup.object().shape({
		roleID: Yup.string().required('Role ID is required'),
		// clientID: Yup.string().when('roleID', {
		// 	is: '3', 
		// 	then: Yup.string().required('Client is required'), 
		// 	otherwise: Yup.string().nullable() 
		// }),
		// clientID: Yup.string().required('Client is required').
		// when,
		// gasStationId: Yup.string().required('Site is required'),
		fullName: Yup.string().required('Full name is required'),
		email: Yup.string().email('Invalid email').required('Email is required'),
		password: Yup.string().required('Password is required'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm password is required!'),
	});

	const initialValues = {
		email: "",
		password: "",
		fullName: "",
		roleID: "",
		gasStationId: "",
		clientID: "",
		confirmPassword: "",
	};

	const handleAddUser = async (values) => {
		console.log("values", values);

		setLoading(true);
		try {
			const request = {
				fullName: values.fullName,
				email: values.email,
				password: values.password,
				roleID: values.roleID,
				// gasStationId: values?.gasStationId?values?.gasStationId:null,
				// clientID: values?.clientID?values?.clientID:null,
				// "phoneNo": "9878987655",
			};

			const response = await fetch(`${API_URL}/api/1.0.0/user/register`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify(request),
			});

			if (response.ok) {
				addToastMessage({
					status: true,
					message: "You have successfully created a user. Thank you!",
				});
				setNewNotification(true);
				setShowNotification(true);
				addNotification({
					id: Date.now(),
					message: "You have successfully created a user!",
					status: true,
					archive: false,
				});
				navigate(`/users?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`);
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message: errorMessage || "Something went wrong. Please try again!",
				});
			}
		} catch (error) {
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a user.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSegmentTitle("Add Gasolina User");
	}, []);

	return (
		<section>
			<PageTitle title='Add Gasolina User' />
			<FullPageLoader isActive={loading} />
			<section className='create-station'>
				<h5 className='color-primary'>Gasolina User Details</h5>
				<p>Enter the Gasolina User details below:</p>
				<br />
				<section className='table-wrapper'>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleAddUser}>
						{({ values, errors, touched, setFieldValue }) => (
							<Form>
								<table>
									<tbody>
										<tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">User Type</p>
												<Field
													name='roleID'
													as='select'
													className={(errors.roleID && touched.roleID ? " error" : "")}
													onChange={(event) => setFieldValue('roleID', event.target.value)}
												>
													<option value=''>Select User Type</option>
													<option value='2'>Sub Admin</option>
													<option value='9'>Gasolina Finance Manager</option>
												</Field>
												<ErrorMessage
													name='roleID'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										{/*	{values.roleID == 3 ? (
											""
											// <tr>
											// 	<td colSpan='6'>
											// 	<p className="title f-w-700 color-primary">Client Name</p>
											// 		<Field
											// 			name='clientID'
											// 			as='select'
											// 			className={
											// 				(errors.clientID && touched.clientID ? " error" : "")
											// 			}
											// 		// className='input-field'
											// 		>
											// 			<option value=''>Select Client</option>
											// 			{clientdata &&
											// 				clientdata.clients.map((client, index) => (
											// 					<option value={client.id} key={index}>
											// 						{client.name}
											// 					</option>
											// 				))}
											// 		</Field>
											// 		<ErrorMessage
											// 			name='clientID'
											// 			component='p'
											// 			className='error-messages'
											// 		/>
											// 	</td>
											// </tr>
										) : (
											<tr>
												<td colSpan='6'>
												<p className="title f-w-700 color-primary">Site Name</p>
													<Field
														name='gasStationId'
														as='select'
														className={
															(errors.gasStationId && touched.gasStationId ? " error" : "")
														}
													>
														<option value=''>Select Site</option>
														{data &&
															data.gasStations.map((s, i) => (
																<option value={s.id} key={i}>
																	{s.name}
																</option>
															))}
													</Field>
													<ErrorMessage
														name='gasStationId'
														component='p'
														className='error-messages'
													/>
												</td>
											</tr>
										)} */}
										<tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">Full Name</p>
												<Field
													name='fullName'
													type='text'
													placeholder='Eg: John Smith.'
													className={
														(errors.fullName && touched.fullName ? " error" : "")
													}
												/>
												<ErrorMessage
													name='fullName'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan='6'>
												<p className="title f-w-700 color-primary">Email</p>
												<Field
													name='email'
													type='email'
													placeholder='Eg: john@xyz.com'
													className={
														(errors.email && touched.email ? " error" : "")
													}
												/>
												<ErrorMessage
													name='email'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										<tr>
											<td colSpan="6">
												<p className="title f-w-700 color-primary">Password:</p>
												<div className="password-field-wrapper">
													<Field
														name="password"
														type={newPasswordVisible ? 'text' : 'password'}
														placeholder="**********"
														className={
															" " + (errors.password && touched.password ? "error" : "")
														}
													/>
													<span
														onClick={toggleNewPasswordVisibility}
														className="eye-icon"
														aria-label={newPasswordVisible ? 'Hide password' : 'Show password'}
													>
														{newPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
													</span>
												</div>
											</td>
										</tr>
										<tr>
											<td colSpan="6">
												<p className="title f-w-700 color-primary">Confirm Password:</p>
												<div className="password-field-wrapper">
													<Field
														name="confirmPassword"
														type={confirmPasswordVisible ? 'text' : 'password'}
														placeholder="**********"
														className={
															" " + (errors.confirmPassword && touched.confirmPassword ? "error" : "")
														}
													/>
													<span
														onClick={toggleConfirmPasswordVisibility}
														className="eye-icon"
														aria-label={confirmPasswordVisible ? 'Hide password' : 'Show password'}
													>
														{confirmPasswordVisible ? <FaRegEyeSlash /> : <FaEye />}
													</span>
												</div>
												<ErrorMessage
													name='confirmPassword'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr>
										{/* <tr>
											<td colSpan='6'>
											<p className="title f-w-700 color-primary">Password</p>
												<Field
													name='password'
													type='password'
													placeholder='Eg: john****'
													className={
														(errors.password && touched.password ? " error" : "")
													}
												/>
												<ErrorMessage
													name='password'
													component='p'
													className='error-messages'
												/>
											</td>
										</tr> */}
										<tr>
											<td colSpan="6" className="btns t-a-r">
												<NavLink to={`/users?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}>
													<ButtonLightTextIcon type="button" title="Cancel" />
												</NavLink>
												<ButtonTextOnly type="submit" title="Create Now" />
											</td>
										</tr>
									</tbody>
								</table>
							</Form>
						)}
					</Formik>
				</section>
			</section>
		</section>
	);
};

export default AddGasolinaSubAdmin;



