import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useUI } from "../../context/ui"; 
import PageTitle from "../../components/common/PageTitle";
import ResultsPerPage from "../_common/ResultsPerPage";
 
import FullPageLoader from "../../components/ui/FullPageLoader"; 
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { CiInboxIn, CiRedo, CiSaveDown1, CiSearch  } from "react-icons/ci";
import { API_URL, APP_CURRENCY, DEFAULT_PER_PAGE } from "../../utilities/constants";
import Pagination from "../../components/common/Pagination";   
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { formatAmountValue, formatDateTime } from "../../utilities/helpers";

const buildQueryParams = (params) => Object.entries(params)
    .filter(([_, value]) => value !== "")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const SettlementTxnReport = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const { setSegmentTitle } = useUI();
    const { id:settlementId } = useParams();

    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get("pageNo") || 0;
    const pageSize = searchParams.get("pageSize") || DEFAULT_PER_PAGE;
    const fromDate = searchParams.get("fromDate") || "";
    const toDate = searchParams.get("toDate") || "";
   
    const fetchData = useCallback(async (endpoint, setState, setLoading) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}${endpoint}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.result.responseCode === 200) {
                    setState(data.data || null);
                } else {
                    setState(null);
                }
            } else {
                setState(null);
            }
        } catch (e) {
            console.error("Error fetching data", e);
            setState(null);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(()=>{
        fetchData( `/ocpl/transaction-history?${ buildQueryParams({
            sortOrder: "desc",
            endDate: toDate,
            startDate: fromDate, 
            pageNo,
            pageSize,
            settlementId,
        })}` , setData, setLoading );
        setSegmentTitle("Settled Transactions");
    },[location.search]);

    const resetFilter = () => { navigate(`/settlements-txn/${settlementId}?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`); };

    return (
        <section className="pos-rel">

            <nav class="breadcrumb">
                <ul class="breadcrumb-list">
                    <li class="breadcrumb-item"><Link to="/dashboard"><p>Dashboard</p></Link></li>
                    <li class="breadcrumb-item"><Link to={`/settlement-history?pageNo=0&pageSize=${DEFAULT_PER_PAGE}`}><p>Settlements</p></Link></li>
                    <li class="breadcrumb-item active"><p>Settled Transactions</p></li>
                </ul>
            </nav>

            <PageTitle title="Settled Transactions" />
            <FullPageLoader isActive={loading} />
            <section>
                <div className="flex center-left responsive-container">
                    <ResultsPerPage targetURL={`/settlements-txn/${settlementId}?${buildQueryParams({ toDate, fromDate, pageNo: 0 })}&pageSize=`} />

                    {/* <Formik
                        initialValues={{ fromDate, toDate }}
                        validationSchema={Yup.object({
                            fromDate: Yup.date().required("Start date is required"),
                            toDate: Yup.date()
                                .required("End date is required")
                                .min(Yup.ref("fromDate"), "End Date must be later than Start Date"),
                        })}
                        enableReinitialize
                        onSubmit={async (values) => {
                            const queryParams = buildQueryParams({
                                fromDate: values.fromDate,
                                toDate: values.toDate,
                                pageNo: 0,
                                pageSize,
                            });
                            navigate(`/settlements-txn/${settlementId}?${queryParams}`);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className="flex center-center" style={{ gap: "12px" }}>
                                <Field
                                    name="fromDate"
                                    type="date"
                                    className={errors.fromDate && touched.fromDate ? "error" : ""}
                                />
                                <Field
                                    name="toDate"
                                    type="date"
                                    className={errors.toDate && touched.toDate ? "error" : ""}
                                />
                                <ButtonTextIcon type="submit" icon={<CiSearch />} />
                            </Form>
                        )}
                    </Formik>

                    <ButtonTextIcon
                        type="button"
                        onClick={resetFilter}
                        icon={<CiRedo />}
                        className="responsive-button"
                    /> */}
                </div>

                <div className="results">
        <div className="rs-table-holder">
          {data?.transactionHistoryResponses && (
            <table className="rs-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Associated acc.</th>
                  <th>Account no.</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {!loading && data.transactionHistoryResponses.length > 0 && data.transactionHistoryResponses?.map((d, i) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor: d.transactionType === "CREDIT" ? "#EAF8F4" : "#FFEBEB",
                    }}
                  >
                    <td>{data.pageNo * data.pageSize + i + 1}</td>
                    <td>{d.associateAccount || "-"}</td>
                    <td>{d.account || "-"}</td>
                    <td className="f-w-700">{d.transactionType === "CREDIT" ? "Credit" : "Debit"}</td>
                    <td>{APP_CURRENCY} {formatAmountValue(d.transactionAmount)}/-</td>
                    <td>{formatDateTime(d.transactionTime)}</td>
                    <td>{d.description}</td>
                    <td>
                        {d.transactionReceipt && <div className="flex center-center">
                            <a href={d.transactionReceipt} target="_blank">
                                <CiInboxIn />
                            </a>
                        </div>}
                    </td>
                  </tr>
                ))}
                {data.transactionHistoryResponses.length === 0 && !loading && (
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center", padding: "10px" }} >
                      <p> No records found. </p>
                    </td>
                  </tr>
              )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {data?.transactionHistoryResponses  && (
        <Pagination
          totalRecords={data.totalRecords}
          pageNo={data.pageNo}
          pageSize={data.pageSize}
          baseUrl={`/settlements-txn/${settlementId}?${buildQueryParams({  fromDate, toDate, })}&`}
        />

      )}
            </section>
        </section>
    )
}

export default SettlementTxnReport;