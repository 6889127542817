import { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FileInput from "../../components/ui/FileInput";
import { API_URL, APP_CURRENCY } from "../../utilities/constants";
import ButtonTextOnly from "../../components/ui/ButtonTextOnly";
import FullPageLoader from "../../components/ui/FullPageLoader";
import PageTitle from "../../components/common/PageTitle";
import { useUI } from "../../context/ui";
import { CiCircleCheck, CiLocationArrow1 } from "react-icons/ci";
import LoaderIcon from "../../components/ui/LoaderIcon";
import RSTabs from "../../components/ui/RSTabs";
import WalletTopUp from "./WalletTopUp";
import ButtonTextIcon from "../../components/ui/ButtonTextIcon";
import { decodeRolesFromToken } from "../../utilities/helpers";

const AdminWallet = () => {
	const [userId, setUserId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(null);
	const [uploadedURL, setUploadedURL] = useState("");
	const [activeTab, setActiveTab] = useState("adminWallet");
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const fileInputRef = useRef(null);
	const {
		setNewNotification,
		setShowNotification,
		addNotification,
		setSegmentTitle,
		addToastMessage,
	} = useUI();

	const userRole = decodeRolesFromToken()[0];

	const ValidationSchema = Yup.object().shape({
		referenceId: Yup.string().required(
			"Wallet Account Number is required!"
		),
		amount: Yup.string()
			.matches(/^\d+(\.\d{1,2})?$/, "Amount must be a valid number with up to two decimal places.")
			.test("not-zero", "Amount must be greater than 0.", (value) => parseFloat(value) > 0)
			.required("Amount is required"),
});

	const initialValues = {
		amount: "",
		referenceId: "",
	};

	const handleUpload = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", image, image.name);
			formData.append("type", "TRANSACTION_RECEIPT");

			const response = await fetch(`${API_URL}/api/1.0.0/uploadFile`, {
				method: "POST",
				headers: { "X-JWT-Assertion": token },
				body: formData,
			});

			if (response.ok) {
				addToastMessage({
					status: true,
					message:
						"You have successfully uploaded transaction receipt, thank you!",
				});
				const responseData = await response.json();
				if (responseData.result.responseCode === 200) {
					setUploadedURL(responseData.data);
				}
			}
			else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message:
						errorMessage ||
						"Something went wrong while uploading transaction receipt. Please try again.",
				});
			}
		} catch (error) {
			console.error("Error uploading file:", error);
			setNewNotification(true);
			addNotification({
				id: Date.now(),
				message:
					"Could not upload the transaction receipt at the moment.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	const fetchUserId = async (referenceId) => {
		try {
			const response = await fetch(
				`${API_URL}/ocpl/wallet/${referenceId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": token,
					},
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				setUserId(responseData.data.userId);
				return responseData.data.userId;
			}
		} catch (error) {
			console.error("Error fetching user ID:", error);
		}
		return null;
	};

	const handleWallet = async (values, { resetForm }) => {
		setLoading(true);
		try {
			const userId = await fetchUserId(values.referenceId);
	
			if (!userId) {
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message: "Looks like you entered an incorrect account number.",
					status: false,
					archive: false,
				});
				return;
			}
	
			const request = {
				amount: values.amount,
				type: "C",
				currency: APP_CURRENCY,
				fromReference: "Gasolina wallet credit amount",
				toReference: "User txn ref number",
				userId,
				transactionReceipt: uploadedURL || "/uploads/data/receipt.pdf",
			};
	
			const response = await fetch(`${API_URL}/ocpl/wallet/admin/txn`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": token,
				},
				body: JSON.stringify(request),
			});
	
			if (response.ok) {
				addToastMessage({
					status: true,
					message: "Your wallet topup is successfully completed, thank you!",
				});
				resetForm();
				setNewNotification(true);
				addNotification({
					id: Date.now(),
					message: "Your wallet topup was successfully completed!",
					status: true,
					archive: false,
				});
	
				// Clear the file input and reset the related state values
				setImage(null);
				setUploadedURL("");
				if (fileInputRef.current) {
					fileInputRef.current.reset();
				}
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				addToastMessage({
					status: false,
					message: errorMessage || "Something went wrong while performing topup. Please try again!",
				});
			}
		} catch (error) {
			setNewNotification(true);
			setShowNotification(true);
			addNotification({
				id: Date.now(),
				message: "Something went wrong while creating a Wallet.",
				status: false,
				archive: false,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSegmentTitle("Wallet");
	}, [setSegmentTitle]);

	const tabOptions = [
		{
			id: "adminWallet",
			title: "Customer Wallet Topup",
			content: (
				<section className='create-station'>
					<h5 className='color-primary'>Wallet Details</h5>
					<p>Enter the wallet details below:</p>
					<br />
					<section className='table-wrapper'>
						<Formik
							initialValues={initialValues}
							validationSchema={ValidationSchema}
							onSubmit={handleWallet}>
							{({
								errors,
								touched,
								setFieldValue,
								resetForm,
								handleSubmit,
							}) => (
								<Form>
									<table>
										<tbody>
											<tr>
												<td colSpan='6'>
													<p className='title f-w-700 color-primary'>
														Amount in {APP_CURRENCY}
													</p>
													<Field
														name='amount'
														maxLength=""
														type='text'
														placeholder='Eg: 1200'
														className={`col-1-1 ${errors.amount &&
															touched.amount
															? "error"
															: ""
															}`}
													/>
													{errors.amount && <p className='error-messages' >{errors.amount}
													</p>  }
												</td>
											</tr>

											<tr>
												<td colSpan='4'>
													<p className='title f-w-700 color-primary'>
														Proof of Transaction
													</p>
													<FileInput
														ref={fileInputRef}
														defaultText='Transaction proof'
														accept='image/jpeg, image/jpg, image/png, application/pdf'
														callback={setImage}
													/>
												</td>
												<td colSpan='2' className='t-a-r'>
													<p>&nbsp;</p>
													<ButtonTextIcon
														type='button'
														title={uploadedURL ? "Uploaded" : "Upload"}
														disabled={loading || !image}
														icon={
															loading ? (
																<LoaderIcon />
															) : uploadedURL ? (
																<CiCircleCheck />
															) : (
																<CiLocationArrow1 />
															)
														}
														onClick={handleUpload}
													/>
												</td>
											</tr>
											{image && (
												<tr>
													<td colSpan='2'>
														<p
															className='cursor-pointer'
															style={{
																fontSize: "12px",
																color: "red",
															}}
															onClick={() => {
																setImage(null);
																setUploadedURL("");
																if (fileInputRef.current) {
																	fileInputRef.current.reset();
																}
															}}>
															Delete transaction receipt file
														</p>
													</td>
												</tr>
											)}


											{/* <tr>
												<td colSpan='4'>
													<p className='title f-w-700 color-primary'>
														Proof of Transaction
													</p>
													<FileInput
														defaultText='Transaction screenshot'
														accept='image/jpeg, image/jpg, image/png, application/pdf'
														callback={setImage}
													/>
												</td>
												<td
													colSpan='2'
													className='t-a-r'>
													<p>&nbsp;</p>
													<ButtonTextIcon
														type='button'
														title={
															uploadedURL
																? "Uploaded"
																: "Upload"
														}
														disabled={
															loading || !image
														}
														icon={
															loading ? (
																<LoaderIcon />
															) : uploadedURL ? (
																<CiCircleCheck />
															) : (
																<CiLocationArrow1 />
															)
														}
														onClick={handleUpload}
													/>
												</td>
											</tr>
												{image && (
													<tr>
														<td colSpan='2'>
															<p
																className='cursor-pointer'
																style={{
																	fontSize: "12px",
																	color: "red",
																}}
																onClick={() => {
																	setImage(null);
																	setUploadedURL(
																		""
																	);
																}}>
																Delete transaction receipt file
															</p>
														</td>
													</tr>

												)} */}
											<tr>
												<td colSpan="6" >
													<p className="status f-w-200 l-h-1 font-size-12">
														NOTE: Please upload file in JPG, JPEG, PNG or PDF format.
													</p>
												</td>
											</tr>
											<tr>
												<td colSpan='6'>
													<p className='title f-w-700 color-primary'>
														Account Number
													</p>
													<Field
														name='referenceId'
														type='text'
														placeholder='Eg: GUAXXXXXXXXXX'
														className={`col-1-1 ${errors.referenceId &&
															touched.referenceId
															? "error"
															: ""
															}`}
													/>
													<ErrorMessage
														name='referenceId'
														component='p'
														className='error-messages'
													/>
												</td>
											</tr>
											<tr>
												<td
													colSpan='6'
													className='btns t-a-r'>
													<ButtonTextOnly
														type='submit'
														title='Submit'
														disabled={
															loading ||
															!uploadedURL
														}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</Form>
							)}
						</Formik>
					</section>
				</section>
			),
		},
		...(userRole === 1
			? [
				{
				  id: "topup",
				  title: "Super Wallet Topup",
				  content: <WalletTopUp />,
				},
			  ]
			: []),
	];

	return (
		<section>
			<PageTitle title='Wallet' />
			<FullPageLoader isActive={loading} />
			<div className='pad-20'>
				<RSTabs
					options={tabOptions}
					activeTab={activeTab}
					onChange={setActiveTab}
				/>
			</div>
		</section>
	);
};
export default AdminWallet;
